.ReactModalPortal > div {
  opacity: 0;
}

button {
  cursor: pointer;
}

.ReactModalPortal .ReactModal__Overlay {
  align-items: start !important;
  margin-top: 5%;
  display: flex;
  justify-content: center;
  transition: opacity 0.2s ease-in-out;
  z-index: 999;
}

.modal-fondo-calendar {
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  position: fixed;
  background: none;
}

.ReactModalPortal .ReactModal__Overlay--after-open {
  opacity: 1;
}

.ReactModalPortal .ReactModal__Overlay--before-close {
  opacity: 0;
}

.rbc-date-cell {
  text-align: center;
  font-size: 12px;
  height: 10px;
}
.rbc-date-cell > a{
  padding: 3px;
  text-align: center !important;
}

.rbc-toolbar .rbc-toolbar-label {
  text-align: start !important;
  text-transform: capitalize;
  border: none !important;
  padding-top: 10px !important;
  margin-bottom: 20px;
  color: #2D4838;
  font-weight: 900;
  font-size: 28px;
}

.btn-calendar-prev {
  visibility: hidden;
}
.btn-calendar-prev:before {
  content: "<";
  visibility: visible;
  font-size: 30px;
  cursor: pointer;
}

.btn-calendar-next {
  visibility: hidden;
}
.btn-calendar-next:before {
  content: ">";
  visibility: visible;
  font-size: 30px;
  cursor: pointer;
}
.view-active {
  color: #2D4838;
}

.rbc-month-view {
  border: none !important;
  align-self: center !important;
  width: 95% !important;
}
.rbc-day-bg + .rbc-day-bg {
  border: none !important;
}
.rbc-month-row + .rbc-month-row {
  border: none !important;
}

.rbc-time-view {
  border: none !important;
}


.rbc-month-header {
  margin-bottom: 20px;
  font-weight: normal;
}
.rbc-header, button.react-calendar__navigation__label > span {
  border: none !important;
  text-transform: capitalize;
  color: rgba(37, 37, 37, .5);
  font-style: normal;
  font-weight: normal;
}
.rbc-today {
  background-color: white;
}

.rbc-header {
  border: none !important;
}

div.table-cont > div > div.rbc-toolbar > span:nth-child(1) > button:nth-child(1) {
  display: none;
}
body > div.ReactModalPortal > div > div > form > div > div > select {
  padding-right: 2.5em;
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  display: flex;
  align-items: center;
  color: #a5a5a5;
}

/* button.react-datetime-picker__clear-button.react-datetime-picker__button {
  display: none !important;
} */

button.react-datetime-picker__calendar-button.react-datetime-picker__button > svg > rect {
  stroke: #a5a5a5;
}
button.react-datetime-picker__calendar-button.react-datetime-picker__button > svg > line {
  stroke: #a5a5a5;
}
.select:not(.is-multiple):not(.is-loading)::after {
  border-color: #A5A5A5;
  right: 1.125em;
  z-index: 0;
}

.react-datetime-picker >.react-calendar__month-view__weekdays {
  color: #002985 !important;
}

div.react-calendar__viewContainer > div > div > div > div.react-calendar__month-view__weekdays{
  color: #002985;
  font-size: 10px;
}
div.react-calendar__viewContainer > div > div > div > div.react-calendar__month-view__weekdays > div > abbr{
  text-decoration: none;
}
.react-calendar__tile--now{
  background: white !important;
  color: #002985 !important;
  font-weight: bold;
}

.react-calendar__tile--active{
  background: #002985 !important;
  color: white !important;
  border-radius: 50px;
  font-weight: 400;
}

.react-calendar__viewContainer > div > div > div > div.react-calendar__month-view__days > button > abbr{
  font-size: 13px;
}
.rbc-header, button.react-calendar__navigation__label > span{
  font-size: 10px;
  font-weight: bold;
}

.react-calendar {
  width: 305px !important;
  background: #FFFFFF;
  box-shadow: 0px 3px 14px rgba(0, 0, 0, 0.03), 0px 3px 14px rgba(19, 10, 46, 0.03), 0px 1px 3px rgba(19, 10, 46, 0.13);
  border-radius: 12px !important;
  border: none !important
}

