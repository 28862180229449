.css-1s2u09g-control, .css-1s2u09g-control, .css-1pahdxg-control{
    height: 60px;
}

.input-select{
    display: block;
}

.input-select .css-1s2u09g-control, .input-select .css-1pahdxg-control{
    height: 100px !important;
}

.input-select-open1 .css-1s2u09g-control, .input-select-open1 .css-1pahdxg-control{
    height: 100px !important;
}
.input-select-open2 .css-1s2u09g-control, .input-select-open2 .css-1pahdxg-control{
    height: 130px !important;
}
.input-select-open3 .css-1s2u09g-control, .input-select-open3 .css-1pahdxg-control{
    height: 160px !important;
}
.input-select-open4 .css-1s2u09g-control, .input-select-open4 .css-1pahdxg-control{
    height: 190px !important;
}
.input-select-open5 .css-1s2u09g-control, .input-select-open5 .css-1pahdxg-control{
    height: 220px !important;
}
.input-select-open6 .css-1s2u09g-control, .input-select-open6 .css-1pahdxg-control{
    height: 250px !important;
}

.title-provider-info{
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;

    /* justo negro */
    color: #333333;

    opacity: 0.5;
}

.value-provider{
    font-family: Lato;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;

    /* identical to box height */

    /* justo negro */
    color: #333333 !important;
}

.icon-load-provider-info{
    padding-top: 17px !important;
}
