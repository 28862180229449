@import url(https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap);
:root {
    --primary: #2D4838;
    --main-bg: #ffffff;
    --second-bg: #FFC102;
    --txt-color: #455560;
    --txt-white: #fff;
    --main-color: #2D4838;
    --second-color: #F2F5F5;
    --box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;

    --main-bg-light: #ffffff;
    --second-bg-light: #fafafb;
    --txt-color-light: #455560;
    --box-shadow-light: rgba(149, 157, 165, 0.2) 0px 8px 24px;

    --main-bg-dark: #2d2d2d;
    --second-bg-dark: #202020;
    --txt-color-dark: #bbbbbb;
    --box-shadow-dark: rgba(0, 0, 0, 0.2) 0px 5px 10px;

    --main-color-blue: #349eff;
    --second-color-blue: #62b4ff;


    --sidebar-width: 100px;
    --border-radius: 5px;
    --topnav-height: 110px;
    --transition-cubic: cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;

    font-family: 'Lato', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    /* letter-spacing: 0.7px; */
    font-size: 16px;
    border: none;

}

a {
    text-decoration: none;
    color: unset;
}

a:hover {
    color: #2D4838;
    color: var(--main-color);
}

/* ul {
    list-style-type: none;
} */

input {
    border: 2px solid transparent;
    outline: 0;
}

/* input:focus {
    border: 2px solid var(--main-color);
} */

.page-header {
    margin-bottom: 40px;
    text-transform: capitalize;
}

.card {
    padding: 30px;
    margin-bottom: 30px;
    background-color: #ffffff;
    background-color: var(--main-bg);
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    box-shadow: var(--box-shadow);
    border-radius: 5px;
    border-radius: var(--border-radius);
}

.full-height {
    height: 100%;
}

.card.full-height {
    height: calc(100% - 30px);
}

.card__header {
    text-transform: capitalize;
}

.card > div ~ div {
    margin-top: 30px;
}

.card__footer {
    text-align: center;
    text-transform: capitalize;
}

.rdt_TableCell div{
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    color: #333333;
    text-align: left;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: unset;
}

.rdt_TableCell div.status-provider{
    text-align: center ;
}

.rdt_Table, .rdt_TableRow, .rdt_TableHeadRow, .rdt_TableHead, .rdt_TableBody{
    background: transparent !important;
}

.fSKJEl:hover, .cZbgXA:hover {
    background-color: #fffefe42 !important;
}

.rdt_TableHeadRow{
    font-family: Inter;
    font-style: normal;
    font-weight: bold !important;
    font-size: 10px !important;
    line-height: 12px !important;
    text-transform: uppercase;
    color: #2D4838;
}

.lnndaO{
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 12px;
    text-transform: uppercase;
    color: #2D4838;
}
.footer{
    height: 61px;
    background: #2D4838;
    text-align: center;
}

.footer ul {
    display: inline-flex;
    justify-content: space-evenly;
    align-items: center;
    height: 100%;
    width: 50%;
    margin: 0;
    padding: 0;
    zoom: 1;
    /* For IE, the outcast */
    zoom:1;
    *display: inline;
}

.footer li {
    opacity: 0.5;
}


.footer ul li {
    float: left;
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #FFFFFF;
}

@media only screen and (max-width: 470px) {
    .footer{
        height: 86px;
    }

    .footer ul li {
        text-align: center;
        float: none;
        font-size: 14px;
        display: block;
    }
    .footer ul {
    padding: 13px 0 0 0;
    display: block;
    justify-content: space-evenly;
    width: 100%;
    display: block;
    }
    
}

@media only screen and (max-width: 768px) {

    .footer ul {
    width: 100%;
    padding: 13px 0 0 0;
    }
    
}

.head{
    border-bottom: 1px solid #cde1d5;
}

.logo-head{
    height: 70px;
    text-align: center;
    padding: 20px;
}

.box-title-breadcrumbs{
    width: 100%;
    height: 186px;

    background: #F2F5F5;
}

.box-title-breadcrumbs-home{
    width: 100%;
    height: 100%;

    background: #F2F5F5;
    margin-bottom: 50px;
}

.breadcrumbs{
    padding-top: 20px;
    padding-left: 20px;
    height: 50px;
}

.link-inactive{
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;

    /* identical to box height */

    /* justo verde */
    color: #2D4838;

    opacity: 0.5;
    padding-right: 10px;
}

.link-active{
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;

    /* identical to box height */

    /* justo verde */
    color: #2D4838;
}

.title-view{
    font-family: Lato;
    font-style: normal;
    font-weight: 800;
    font-size: 40px;
    line-height: 48px;
    margin: 43px 0;

    /* identical to box height */

    /* justo verde */
    color: #2D4838;
}

.image-home,
.image-home-hand{
    text-align: center;
}

.image-home-hand img {
    max-width: 320px;
    width: 100%;
}

.text-home{
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;

    /* justo verde */
    color: #2D4838;

    opacity: 0.7;
}

.text-home img{
    float: right;
    margin-top: 5px;
}

.text-underline-home{
    border-bottom: 2px solid #2D4838;
    cursor: pointer;
}

.icon-close{
    text-align: center;
    cursor: pointer;
}

.title-modal{
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    text-transform: uppercase;

    /* justo negro */
    color: #333333;

    opacity: 0.7;
    padding-top: 30px;
    padding-bottom: 40px;
}

.item-breadcrumb{
    cursor: pointer;
}

.documents {
    width: 360px;
    padding-left: 24px;
}

@media only screen and (max-width: 470px) {
    .title-view{
        font-weight: 800;
        font-size: 1.5rem;
        margin-top: 0;
    }

    .title-view img{
        width: 16px;
    }
    .image-home-hand img {
        max-width: 220px;
        width: 100%;
        margin-top: 22px;
    }

    .box-title-breadcrumbs {
        width: 100%;
        height: 114px;
        background: #F2F5F5;
    }
    .documents {
        width: 273px;
        padding-left: 24px;
        font-size: 12px;
    }
    .title-modal {
        padding-bottom: 10px;
    }
    
}
.container-home{
    text-align: center;
    padding-top: 50px;
    padding-bottom: 10rem;
}

.card-home{
    width: 338px;
    height: 93px;

    /* justo blanco */
    background: #FFFFFF;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.15);
    border-radius: 10px;
    margin-bottom: 35px;
    cursor: pointer;
}

.card-home-disable{
    opacity: 0.5;
}

.title-card{
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;

    /* identical to box height */

    /* justo verde */
    color: #2D4838;


    /* Inside Auto Layout */
    flex: none;
    order: 0;
    flex-grow: 0;
    margin: 10px 0px;
    float: left;
}

.button-card{
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;

    /* justo negro */
    color: #333333;

    opacity: 0.5;

    /* Inside Auto Layout */
    flex: none;
    order: 1;
    flex-grow: 0;
    margin: 10px 0px;
    float: left;
}

.button-card-success{
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;

    color: #31CC2E;

    /* Inside Auto Layout */
    flex: none;
    order: 1;
    flex-grow: 0;
    margin: 10px 0px;
    float: left;
}

.button-card-success .img{
    float: right;
    width: 25px;
    padding-top: 1px;
    padding-left: 5px;
}

.card-home img{
    float: right;
    width: 18px;
    padding-top: 10px;
}

.button-finish{
    width: 338px;
    height: 60px;

    /* justo verde */
    background: #2D4838;
    opacity: 0.2;
    border-radius: 5px;
}

.button-finish-active{
    opacity: 1 !important;
}

.button-finish .text{
    font-family: Lato;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;

    /* identical to box height */
    text-align: center;

    /* justo blanco */
    color: #FFFFFF;
}

.center-card{
    text-align: -webkit-center;
    text-align: -moz-center;
}

@media only screen and (max-width: 470px) {
    .card-home{
        width: 100%;
    }
    .button-finish{
        width: 100%;
    }
    .card-home {
        margin-bottom: 30px;   
    }
    .container-home{
        text-align: center;
        padding-top: 30px;
        padding-bottom: 3rem;
    }
}


.container-load-products{
    padding-bottom: 40px;
}

.title-section{
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;

    /* identical to box height */
    text-transform: uppercase;

    /* justo negro */
    color: #333333;

    opacity: 0.7;
    margin-bottom: 50px;
}

.label{
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;

    /* justo negro */
    color: #333333;
    padding-bottom: 30px;
}

.button-load-products, .button-load-products-modal{
    width: 460px;
    height: 60px;

    /* justo blanco */
    background: #FFFFFF;

    /* justo verde */
    border: 1px solid #2D4838;
    box-sizing: border-box;
    border-radius: 5px;
    margin-bottom: 50px;
}

.button-load-products, .button-load-products-modal .img-button{
    text-align: center;
    padding-top: 17px;
}

.button-load-products, .button-load-products-modal .text-button{
    padding-top: 15px;
    font-family: Lato;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;

    /* identical to box height */
    text-align: center;

    color: #2D4838;
}

.button-load-products-file, .button-load-products-file-modal{
    width: 460px;
    height: 60px;

    background: rgba(2, 70, 56, 0.05);
    border: 1px solid rgba(45, 72, 56, 0.2);
    box-sizing: border-box;
    border-radius: 5px;
    margin-bottom: 50px;
}

.button-load-products-file, .button-load-products-file-modal .text-button-not-loaded{
    padding-top: px !important;
    text-align: center;
}

.button-load-products-file, .button-load-products-file-modal .img-button{
    background: transparent;
}

.button-load-products-file, .button-load-products-file-modal .text-button{
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: 13px !important;
    line-height: 17px;
    text-align: left;
    
    /* identical to box height */
    
    /* justo negro */
    color: #333333;
    
    opacity: 0.7;
}

.button-load-products-file, .text-load-modal, .button-load-products-file-modal .text-load{
    padding-left: 10px;
    font-family: Lato;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;

    /* identical to box height */

    /* justo negro */
    color: #333333;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.text-load-product, .img-button-product {
    display: flex;
    align-items: center;
    margin-top: 16px;
}

.text-underline{
    border-bottom: 2px solid #333333;
}

.text-underline-load-products{
    font-size: 13px;
    border-bottom: 1px solid #333333;
}

.button-save{
    width: 338px;
    height: 60px;

    /* justo verde */
    background: #2D4838;
    opacity: 0.2;
    border-radius: 5px;
}

.button-save-active{
    width: 338px;
    height: 60px;

    /* justo verde */
    background: #2D4838;
    opacity: 1;
    border-radius: 5px;
}


.button-save .text, .button-save-active .text{
    font-family: Lato;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;

    /* identical to box height */
    text-align: center;

    /* justo blanco */
    color: #FFFFFF;
}

#selectedFile{
    width: auto !important;
    display: none;
}


@media only screen and (max-width: 470px) {
    .button-load{
        width: 100%
    }
    .button-load-products,
    .button-load-products-file {
        width: 100%;
    }
    .button-load-products .text-button {
        font-size: 1rem;
      
    }
    
}

.section-register {
  padding-top: 30px;
  padding-bottom: 50px;
  border-bottom: 0.75px solid #2d483824;
}

.title-section {
  display: flex;
  flex-direction: row;
  grid-gap: 10px;
  gap: 10px;
  align-items: center;
  justify-content: space-between;
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  text-transform: uppercase;
  color: #333333;
  opacity: 0.7;
  margin: 40px 0;
}

.label-register {
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;

  /* justo negro */
  color: #333333;
  padding-bottom: 10px;
  height: 40px;
}

.label-register-password {
  height: 40px;
  line-height: 16px;
}

.label-register p {
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 17px;

  /* identical to box height */

  /* justo negro */
  color: #333333;

  opacity: 0.5;
}

.input-register {
  width: 100%;
  height: 60px;

  /* justo blanco */
  background: #ffffff;
  border: 1px solid rgba(45, 72, 56, 0.2);
  box-sizing: border-box;
  border-radius: 5px;
  padding-left: 10px;
}
.input-oc {
  width: 100%;
  height: 60px;

  /* justo blanco */
  background: #ffffff;
  border: 1px solid rgba(45, 72, 56, 0.2);
  box-sizing: border-box;
  border-radius: 5px;
  padding-left: 10px;
}

.button {
  width: 460px;
  height: 60px;

  /* justo blanco */
  background: #ffffff;

  /* justo verde */
  border: 1px solid #2d4838;
  box-sizing: border-box;
  border-radius: 5px;
  margin-bottom: 50px;
}

.button .img-button {
  text-align: center;
  padding-top: 17px;
}

.button .text-button {
  padding-top: 15px;
  font-family: Lato;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;

  /* identical to box height */
  text-align: center;

  color: #2d4838;
}

.button-load-register {
  width: 100%;
  height: 60px;

  background: rgba(2, 70, 56, 0.05);
  border: 1px solid rgba(45, 72, 56, 0.2);
  box-sizing: border-box;
  border-radius: 5px;
}

.button-load-register .img-button-not-loaded {
  text-align: center;
  padding-top: 5px;
}

.button-load-register .text-button-not-loaded {
  padding-top: 5px !important;
}

.button-load-register .img-button {
  text-align: center;
  /* padding-top: 17px; */
  background: transparent;
}

.img-button img {
  padding-top: 17px;
}

.button-load-register .text-button {
  padding-top: 17px;
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 13px !important;
  line-height: 17px;

  /* identical to box height */

  /* justo negro */
  color: #333333;

  opacity: 0.7;
  text-align: left;
}

.button-load-register .text-load {
  padding-top: 15px;
  padding-left: 10px;
  font-family: Lato;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;

  /* identical to box height */

  /* justo negro */
  color: #333333;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.text-underline {
  border-bottom: 2px solid #333333;
}

.text-underline-load {
  border-bottom: 1px solid #333333;
}

.button-save {
  width: 338px;
  height: 60px;

  /* justo verde */
  background: #2d4838;
  opacity: 0.2;
  border-radius: 5px;
}

.button-save-active {
  width: 338px;
  height: 60px;

  /* justo verde */
  background: #2d4838;
  opacity: 1;
  border-radius: 5px;
}

.button-save .text,
.button-save-active .text {
  font-family: Lato;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;

  /* identical to box height */
  text-align: center;

  /* justo blanco */
  color: #ffffff;
}

#selectFileBankFace,
#selectFileOpinionFulfillmentSat,
#selectFileProofAddress,
#selectFileProofTaxSituation,
#selectFileConstitutiveAct,
#selectFileOpinionFulfillmentTaxObligations,
#selectFilePowerAttorney,
#selectFileIdentificationLegalRepresentativeFront,
#selectFileIdentificationLegalRepresentativeReverse {
  width: auto !important;
  display: none;
}

.MuiGrid-spacing-xs-10 > .MuiGrid-item {
  padding-bottom: 0px !important;
  padding-top: 20px !important;
}

.button-register {
  width: 100%;
  padding-bottom: 60px;
  text-align: center;
}

.MuiNativeSelect-select:focus {
  background-color: transparent !important;
}

.linear-progress {
  padding: 10px;
}

.borF {
  border-bottom: none;
}

@media only screen and (max-width: 470px) {
  .label-register {
    font-size: 16px;
  }

  .label-register-password {
    height: 60px;
  }
  .button-save {
    width: 100%;
  }

  .button-register {
    width: 100%;
    margin-top: 0;
    padding-bottom: 30px;
    text-align: center;
  }
  .title-section {
    margin: 20px 0;
  }
}

.css-1s2u09g-control, .css-1s2u09g-control, .css-1pahdxg-control{
    height: 60px;
}
.title-table-orders{
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 12px;

    /* identical to box height */
    text-transform: uppercase;

    /* justo verde */
    color: #2D4838;
}

.icon-export-provider{
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    text-align: center;

    /* justo negro */
    color: #333333;
    float: left;
    padding-right: 5px;
    padding-left: 5px;
    margin-top: -8px;
}

.icon-export-provider a{
    color: #333333;
    font-size: 12px;
    line-height: 14px;
}

.icon-export-provider a:hover{
    text-decoration: none;
}


.icon-export-provider.empty{
    background: #e0e4e2;
}

.empty img{
    padding-top: 5px;
    padding-bottom: 3px;
}

.icon-action-provider{
    padding-right: 10px;
    padding-left: 10px;
    float: left;
}

.body-provider{
    margin-left: 80px;
    padding-bottom: 50px;
}

.status-orders{
    width: 123px !important;
    height: 29px !important;
}

.btnAddProduct{
    margin-top: 29px;
    width: 165px;
    height: 50px;

    border: 1px solid rgba(45, 72, 56, 0.5);
    box-sizing: border-box;
    border-radius: 5px;

    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 22px;
    color: #333333;
    background: white;
}

.total-orders{
    padding: 10px;
    width: 100%;
    text-align: right;
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: 10px;
    line-height: 12px;

    /* identical to box height */
    text-transform: uppercase;

    /* justo verde */
    color: #2D4838;
}

.total-orders span{
    padding-left: 30px;
    padding-right: 10px;
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 22px;

    /* identical to box height */

    /* justo negro */
    color: #333333;
}

.created_at{
    padding-left: 15px;
}

.icon-remove{
    margin-top: -1px;
}

.item-actions{
    float: right;
}

.text-center{
    text-align: center;
}

.container-select-day {
    margin: 0 auto;
    width: 98%;
}

.item-privider {
    font-family: Lato;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    color: #333333;
    padding-bottom: 30PX;
}

.label-order {
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;
    color: #333333;
    padding-bottom: 10px;

}

.container-modal-btn-schedule {
    width: 100%;
    margin-top: 40px;
}
.container-sidebar{
    position: absolute;
    width: 80px;
    background: white;
    padding-left: 5px;
}

.list-sidebar{
    width: 70px;
    background: #F2F5F5;
    padding-left: 10px;
    padding-right: 10px;
    height: 932px;
}

.list-sidebar li{
    height: 75px;
    width: 70px;
    padding-top: 15px;
    text-align: center;
    list-style: none;
    margin-left: -10px;
    cursor: pointer;
}

.list-sidebar li span{
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 12px;

    /* identical to box height */
    text-align: center;

    /* justo verde */
    color: #2D4838;

    opacity: 0.5;
    display: block;
}

.list-sidebar li span.active{
    font-weight: bold;
}

.active{
    background: white;
}
.header-provider{
    margin-left: 80px;
    height: 222px;  
    background: #F2F5F5;
    padding-top: 40px;
}

.header-provider-detail{
    margin-left: 80px;
    height: 169px;
    background: #F2F5F5;
}

.header-provider-detail .title{
    font-family: Lato;
    font-style: normal;
    font-weight: 800;
    font-size: 40px;
    line-height: 48px;

    /* identical to box height */

    /* justo verde */
    color: #2D4838;
    margin-left: 80px;
    padding-top: 28px;
}


.title-view-header-provider{
    font-family: Lato;
    font-style: normal;
    font-weight: 800;
    font-size: 40px;
    line-height: 48px;

    /* identical to box height */

    /* justo verde */
    color: #2D4838;
}

.title-view-header-provider-detail{
    padding-left: 80px;
    padding-top: 27px;
}

.input-header-search-provider{
    height: 45px;
    padding-left: 20px;
    padding-right: 20px;

    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 22px;

    /* identical to box height */

    /* justo negro */
    color: #333333;
    width: 80%;
    opacity: 0.3;
}


.con-provider { 
    background: #FFFFFF;
    border: 1px solid rgba(45, 72, 56, 0.2);
    box-sizing: border-box;
    border-radius: 5px;
    height: 50px;
    
    position:relative; 
    margin-top: 29px;
}

.con-provider input { 
    margin:0 0 0 4px; 
    border:0; 
}

.con-provider .MuiSvgIcon-root { 
    display:block; 
    position:absolute; 
    top: 10px; 
    right: 20px; 
    cursor:pointer; 
    font-size: 2rem;
    color: gray;
}

.button-header-provider{
    margin-left: 10px;
    margin-top: 29px;
    height: 50px;

    border: 1px solid rgba(45, 72, 56, 0.5);
    box-sizing: border-box;
    border-radius: 5px;
    width: 110px;
    padding: 12px 15px;
}

.button-header-provider span{
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 22px;

    /* identical to box height */

    /* justo negro */
    color: #333333;

    opacity: 0.5;
    padding-right: 12px;
}

.button-header-new, .btnDetails{
    margin-top: 29px;
    width: 235px;
    height: 50px;

    /* justo verde */
    background: #2D4838;
    border-radius: 5px;
}

.btnDetails {
    width: 295px;
}

.button-header-new, .btnDetails .text{
    font-family: Lato;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;

    /* identical to box height */

    /* justo blanco */
    color: #FFFFFF;
}

.title-modal-provider{
    font-family: Lato;
    font-style: normal;
    font-weight: 800;
    font-size: 25px;
    line-height: 30px;
    line-height: 19px;
    text-align: center;

    /* justo negro */
    color: #2D4838;
    background-color: #F2F5F5;
    padding: 25px 20px;
    border-radius: 10px;
    margin-bottom: 47px;
}

.title-modal-provider img {
    float: right;
}

.documents-modal-provider {
    display: flex;
    width: 100%;
    justify-content: center;
}

.button-save-modal{
    width: 194px;
    height: 60px;

    /* justo verde */
    background: #2D4838;
    opacity: 0.2;
    border-radius: 5px;
}

.button-save-modal .text, .button-save-modal-active, .button-save-incidence .text{
    font-family: Lato;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;

    /* identical to box height */
    text-align: center;

    /* justo blanco */
    color: #FFFFFF;
}

.container-btn-save {
    margin: 0 auto;
    width: 195px;
}

.button-load-products-modal, .button-load-products-file-modal {
    width: 490px;
}

.rigth{
    float: right;
    margin-left: 20px;
    min-width: 135px;
    cursor: pointer;
}

.button-load-incidence{
    width: 100%;
}

.rigth img {
    opacity: 50%;
}

.button-load-products-file, .button-load-products-file-modal .text-load{
    background: rgba(2, 70, 56, 0.05);
    width: 100%;
}

@media only screen and (max-width: 470px) {
    .input-header-search-provider{
        padding-left: 10px;
        padding-right: 0;
    }
    
    .button-header-provider span{
        font-size: 14px;
    }
    
    .button-header-new{
        margin-top: 10px;
        width: 206px;
    }
    
    .button-header-new .text{
        font-size: 14px;
    }
    .con-provider {
        margin-top: 0;
    }

    .header-provider{
        height: 100%;
        padding: 20px 0;

    }
    
}
.NotFound {
    padding-top: 100px;
}

.title-404{
  font-size: 100px;
  color: #2D4838;
}


.title-page{
  font-size: 40px;
  color: #2D4838;
  padding-bottom: 50px;
}
.container-finish{
    background: #F2F5F5;
    padding-bottom: 50px;
}

.image{
    height: 150px;
    padding-top: 40px;
    width: 100%;
}

.image .one{
    float: right;
    padding-right: 61px;
    display: flex;
}

.image .two{
    float: left;
    padding-left: 50px;
    display: flex;
}

.card{
    width: 500px;
    height: 480px;

    /* justo blanco */
    background: #FFFFFF;
    border: 0.802065px solid rgba(45, 72, 56, 0.2);
    box-sizing: border-box;
    border-radius: 8.02065px;
    text-align: center;
}

.card .text{
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 24px;
    text-align: center;

    /* justo negro */
    color: #333333;

    opacity: 0.7;
}

.button-finish-finish{
    width: 338px;
    height: 60px;

    /* justo verde */
    background: #2D4838;
    border-radius: 5px;
}

.button-finish-finish .text{
    font-family: Lato;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;

    /* identical to box height */
    text-align: center;

    /* justo blanco */
    color: #FFFFFF;
    opacity: 1 !important;
}

.center-card{
    text-align: -webkit-center;
    text-align: -moz-center;
}

@media only screen and (max-width: 470px) {
    .card{
        width: 100%;
    }
    .button-finish-finish{
        width: 100%;
    }
    .image{
        height: 0;
        width: 0;
    }
    .image .one{
        display: none;
    }

    .image .two {
        display: none;
    }


}
.logo-admin{
    height: 70px;
    padding: 20px;
    padding-left: 0px;
}

.menu{
    height: 70px;
    padding: 25px;
    padding-right: 0px;
    float: right;

    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    text-align: center;

    /* justo negro */
    color: #333333;
    text-transform: none !important;

}

.arrow-logout{
    padding-left: 15px;
}

.box-title-admin{
    width: 100%;
    height: 272px;

    background: #F2F5F5;
    margin-bottom: 58px;
}

.box-title-admin-provider{
    width: 100%;
    height: 186px;

    background: #F2F5F5;
    margin-bottom: 58px;
}

.title-min{
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;

    /* identical to box height */

    /* justo verde */
    color: #2D4838;

    opacity: 0.5;
    padding: 24px 0 0 24px;
}

.title-view-admin{
    font-family: Lato;
    font-style: normal;
    font-weight: 800;
    font-size: 40px;

    /* identical to box height */

    /* justo verde */
    color: #2D4838;
    padding-top: 48px;
    padding-bottom: 40px;
}

.title-view-admin img {
    max-width: 30px;
}

.head-1{
    border-bottom: 1px solid #cde1d5;
}

.input-header-search{
    height: 55px;
    padding-left: 20px;
    padding-right: 20px;

    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 24px;

    /* identical to box height */

    /* justo negro */
    color: #333333;

    opacity: 0.3;
}

.con { 
    background: #FFFFFF;
    border: 1px solid rgba(45, 72, 56, 0.2);
    box-sizing: border-box;
    border-radius: 5px;
    height: 60px;
    
    width:100%; 
    position:relative; 
}

.con input { 
    margin:0 0 0 4px; 
    border:0; 
}

.con .MuiSvgIcon-root { 
    display:block; 
    position:absolute; 
    top: 15px; 
    right: 20px; 
    cursor:pointer; 
    font-size: 2rem;
    color: gray;
}

.MuiList-padding{
    width: 170px !important;
}

.MuiListItem-button{
    width: 170px !important;
}

@media only screen and (max-width: 725px) {
    .title-view-admin{
        padding-top: 22px;
        padding-bottom: 20px;
        font-size: 1.3rem; 
    }
    
    .head{
        border-bottom: 1px solid #cde1d5;
    }
    .box-title-admin {
        width: 100%;
        height: 202px;
        background: #F2F5F5;
        margin-bottom: 24px;
    }
    .title-view-admin img {
        max-width: 22px;
        font-size: 1.46rem;
    }
    
}
.title-table{
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    text-transform: uppercase;

    /* justo verde */
    color: #2D4838;
}

.desk {
    display: block

}

.mobile {
    display: none;
}

.text-item-table{
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 24px;

    /* identical to box height */

    /* justo negro */
    color: #333333;
}

.row-title{
    border-bottom: 1px solid #c6c4c4;
}

.row-body{
    border-bottom: 1px solid #e5e3e3;
}

.row-table{
    padding-top: 20px;
    padding-bottom: 20px;
}

.not-first{
    text-align: center;
}

.menu-status{
    text-transform: none !important;
}

.item-status-provider{
    margin-top: -5px;
    font-size: 14px;
}

.menu-status-w {
    width: 10px;
}

.status{
    width: 135px;
    height: 41px;
    border-radius: 40px;
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #333333;
    
    margin-top: -6px;
}

.status.pending{
    background: #ffedcb;
    color: red;
}

.status.active{
    background: #d5f5d5;
}

.status.inactive{
    background: #f6f7f6;
}

.product{
    display: block;
}

.product, .date {
    display: flex;
}

.department{
    text-align: left;
    border-bottom: 0.5px solid #eae7e7;
}

.icon-more{
    color: #939797;
    cursor: pointer;
}

.icons-header-table{
    width: 100%;
}

.icons-header-table .icon-export{
    float: right;
    width: 3rem;
    cursor: pointer;
}

.MuiGrid-grid-xs-3 {
    flex-grow: 0;
    max-width: 25%;
    flex-basis: 25%;
    display: flex;
}

.providers {
    margin-bottom: 20rem;
}

.providers .paginator{
    padding-top: 30px;
}

.providers .paginator nav{
    float: right;
}

.container-details {
    display: flex;
    justify-content: space-between;
}

.content-card {
    font-family: Lato;
    font-style: normal;
    font-weight: 800;
    font-size: 25px;
    line-height: 30px;
    text-align: center;
    height: 234px;
    align-items: center;
    display: flex;
    justify-content: center;
}

@media only screen and (max-width: 470px) {
    .product, .date {
        display: none;
    }
    .MuiGrid-grid-xs-3 {
        max-width: 25%;
        flex-basis: 0;
    }
    .MuiGrid-grid-xs-3 {
        display: none;
    }
    .providers {
        margin-bottom: 91px;
    }
}

@media only screen and (max-width: 768px) {

    .status{
        font-size: 15px;
        width: 75px;

    }
    .desk {
        display: none
    
    }
    
    .mobile {
        display: flex;

    }
    
}

.department{
    display: block;
}

.product{
    display: block;
    text-align: left;
}

.MuiTabs-centered {
    justify-content: center;
    background: #f2f5f5 !important;
}

.MuiTab-textColorPrimary.Mui-selected {
    color: #2d4838 !important;
}

.PrivateTabIndicator-colorPrimary-2 {
    background-color: #2d4838 !important;
}

.PrivateTabIndicator-colorPrimary-4 {
    background-color: #2d4838 !important;
}

.bgtabs {
    background-color: #F2F5F5;
}

.MuiTab-wrapper {
    font-weight: 700;
    text-transform: capitalize;
}

.button-Close-ticket {

width: 174px;
height: 50px;
left: 592px;
top: 1223px;

/* justo verde */

border: 1px solid #2D4838;
box-sizing: border-box;
border-radius: 5px;
margin-left: 20px;
}


.container {
    display: flex;
    width: 574px;
    height: 580px;
    left: 141px;
    top: 231px;
    
    background: #F2F5F5;
      
    }
    .div {
        display: flex;
        flex-direction: column;
        align-items: center
    }
    .div h3 {
        width: 364px;
        height: 19px;
        left: 246px;
        top: 464px;
        
        font-family: Lato;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 19px;
        text-align: center;
        
        /* justo negro */
        
        color: #333333;
    }
    .avatar {
      margin: 1px;
      background-color: #2D4838;
    }
    .button {
        width: 360px;
        height: 60px;        
        /* justo verde */
        
        border: 1px solid #2D4838;
        box-sizing: border-box;
        border-radius: 5px;
    }
    
    .buttonLogin {
        width: 100%;
        height: 60px;
        margin-top: 20px;
        background: #F2F5F5;
        border-radius: 5px;
        border: none;
        font-size: 18px;
    }

    .buttonLogin-active, .buttonRegister {
      text-transform: capitalize;
      font-family: Lato;
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      margin-top: 20px;
      box-Sizing: border-box;
      border-radius: 5px;
      background-color: #2D4838;
      color: #fff;
      border: none;
      width: 100%;
      height: 60px;
    }
    /* .buttonLogin:hover {
      background-color: #2D4838
    } */
    
    .buttonRegister {
      border: 1px solid #2D4838;
      line-height: 22px;
      color: #2D4838;
      background-color: #F2F5F5;
      width: 360px;
    }
    
    /* .img {
      position: 'relative',
      display: 'flex',
      top: '-31px',
      left: '151px'
    },
    .imgbttn {
      position: 'relative',
      display: 'flex',
      botton: '0',
      right: '151px',
      width: '103px'
    } */

.error {
  font-size: 14px;
  color: rgb(252, 56, 56);
}

.text-red-700{
  color: red;
}

.text-red-700 .text-xs{
    font-size: 12px;
}


.label-login{
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  /* justo negro */
  color: #333333;
  padding: 10px 0;
  text-transform: none;
}

.label-login p{
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 17px;
  color: #333333;
  opacity: 0.5;
}

.label-title-register {
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  text-transform: uppercase;

  /* justo negro */

  color: #333333;
}

.img-register, .img2 {
  display: flex;
  position: relative;
}

.img-register {
  top: -4rem;
  left: 11rem;
}

.img2 {
  left: -11rem;
  bottom: -4rem;
}

.span {
  cursor: pointer;
  text-decoration: underline;
  text-transform: none;
}

.TextField {
  height: 60px;
  box-sizing: border-box;
  border-radius: 5px;
  margin: 0,
}



@media only screen and (max-width: 470px) {
  
  .label-title-register {
    font-size: 14px;
    line-height: 17px;
  }
}

@media only screen and (max-width: 786px) {
  .button {
    width: 100%
  }

  .img, .img2 {
    display: none;
  }
}

@media only screen and (max-width: 1024px) {
  .img {
    top: -4rem;
    left: 8rem;
  }
  
  .img2 {
    left: -6rem;
    bottom: -4rem;
  }

  .buttonRegister {
    width: 100%;
  }
}



.topnav {
    display: flex;
    align-items: center;
    justify-content: center;
    /* justo verde */
    
    border: 1px solid #2d48381f;
    background-color: white ;
}

.topnav__logo {
    height: 70px;
    text-align: center;
    padding: 20px;
}

.topnav__search > input {
    height: 100%;
    width: 100%;
    padding: 10px 60px 10px 20px;
    font-size: 1rem;
    border-radius: var(--border-radius);
    color: var(--txt-color);
    background-color: var(--main-bg);
}

.topnav__search > i {
    font-size: 1.5rem;
    position: absolute;
    right: 20px;
}

.topnav__right {
    display: flex;
    align-items: center;
}

.topnav__right-item ~ .topnav__right-item {
    margin-left: 30px;
}

.notification-item {
    display: flex;
    align-items: center;
    padding: 20px;
}

.notification-item:hover {
    background-color: var(--second-bg);
}

.notification-item > i {
    margin-right: 20px;
    font-size: 1.5rem;
}

.topnav__right-user {
    display: flex;
    align-items: center;
}

.topnav__right-user__image {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    overflow: hidden;
    margin-right: 10px;
}

.topnav__right-user__image > img {
    width: 100%;
}

.topnav__right-user__name {
    font-size: 1rem;
    font-weight: 600;
}
.status-incidences{
    width: 150px !important;
    height: 29px !important;
}

.icon-user {
    width: 26px;
    height: 26px;
    left: 421px;
    top: 336px;
    object-fit: none;
    /* justo blanco */

    background: #FFFFFF;
    border: 1px solid rgba(51, 51, 51, 0.1);
    border-radius: 50%;
    margin: 10px 10px 0 0;
}

.icon-user img {
    left: 8.6%;
    right: 8.48%;
    top: 8.6%;
    bottom: 8.31%;
}

.content-user {
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    display: flex;
    align-items: center;
    color: #333333;
}

.date-send {
    width: 176px;
    width: 176px;
    opacity: 1;
    font-family: Lato;
    font-style: italic;
    font-weight: normal;
    font-size: 12px;
    padding-top: 10px;
    color: #929494;
}

.container-message {
    border-top: 1px solid rgba(0, 0, 0, .1);
    padding: 5px;
    margin-top: 2px;
}
.text-details-incidence {
    font-family: Lato;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: left;
    padding: 0 0 0 37px;
    color: #333333;
}

.link-file {
    color: rgba(0,0,0,250,.5);
    font-weight:'500';

}

.text-details-incidence img {
    padding: 32px 0;
}

.content-bg, .content {
    width: 100%;
    height: 167px;
    left: 398px;
    top: 657px;
    padding: 16px 0 0 16px;
    background: #F2F5F5;
    border-top: 1px solid rgb(51 51 51 / 16%);
    border-bottom: 1px solid rgb(51 51 51 / 16%);

}

.content {
    background: #FFFFFF;
    border-top: none;
    border-bottom: none;
    height: 100%;
    min-height: 167px;
}

.container-btn-response {
    padding: 30px 0;
}
.text-new-incidence{
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;

    /* justo negro */
    color: #333333;
    padding-top: 37px;
}

.text-new-incidence-not-first{
    padding-bottom: 37px;
}

.button-load-file-form{
    margin-bottom: 0px;
    background: rgba(2, 70, 56, 0.05);
}

.text-end{
    text-align: center;
}

.label-form{
    height: 25px;
}

.text-area-form{
    height: 109px;
    padding: 10px;
}

#selectFileOC,
#selectFileEvidence{
    width: auto !important;
    display: none;
}

.button-save-incidence{
    width: 194px;
    height: 60px;

    /* justo verde */
    background: #2D4838;
    opacity: 0.2;
    border-radius: 5px;
}

.button-load-incidence {
    width: 100%;
}

.button-save-active{
    opacity: 1;
}
.textarea{
    height: 111px;
    padding: 10px;
}

.button-save-form-product{
    margin-top: 60px;
}
.iframe-reports{
    border: none;
    width: 100%;
    padding-bottom: 30px;
    height: 760px;
}

.error-reports{
    border: none;
    width: 100%;
    padding-bottom: 30px;
    padding-top: 100px;
    height: 760px;
    text-align: center;
}

.text-error{
    font-family: Lato;
    font-style: normal;
    font-weight: 800;
    font-size: 40px;
    line-height: 48px;
    color: #2D4838;
}
.title-table-orders{
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 12px;

    /* identical to box height */
    text-transform: uppercase;

    /* justo verde */
    color: #2D4838;
}

.sort{
    cursor: pointer;
}

.item-provider{
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;

    /* identical to box height */

    /* justo negro */
    color: #333333;
    text-align: left;
}

.item-provider span{
    font-size: 14px !important;
}

.item-status{
    text-align: center;
    padding-top: 4px;
}

.icon-export-provider{
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    text-align: center;

    /* justo negro */
    color: #333333;
    float: left;
    padding-right: 10px;
}

.status-provider{
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: Lato sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    text-align: center;

    /* justo negro */
    color: #333333;

    opacity: 0.7;
    width: 82px;
    height: 29px;

    background: #D6F5D5;
    border-radius: 40px;
    margin-top: -6px;
}

.status-provider.pending{
    background: #ffedcb;
    color: red;
}

.status-provider.active{
    background: #d5f5d5;
}

.status-provider.inactive{
    background: #f6f7f6;
}

.MuiPaginationItem-page.Mui-selected, .MuiPaginationItem-page.Mui-selected:hover{
    background: #2D4838 !important;
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;

    color: #FFFFFF !important;
}

.MuiPaginationItem-page, .MuiPaginationItem-page:hover{
    background: #e0e4e2 !important;
    font-family: Lato !important;
    font-size: 12px !important;
    line-height: 14px !important;

    /* justo verde */
    color: #2D4838 !important;
}

.mobilNone {
    display: block;
}

.tooltip{
    cursor: pointer;
}

.modal-delete{
    width: 200;
    background-color: white;
    border: 1px solid #fff;
    border-radius: 10px;
    /* box-shadow: , */
    padding: 20px;
    outline: 0;
}

.modal-import{
    width: 584px;
    background-color: white;
    border: 1px solid #fff;
    border-radius: 10px;
    /* box-shadow: , */
    padding: 20px;
    outline: 0;
}

.modal-content {
    display: flex;
    align-items: center;
    justify-content: center;
}

.button-delete{
    cursor: pointer;
    width: 194px;
    height: 50px;

    /* justo verde */
    background: #2D4838;
    border-radius: 5px;

    font-family: Lato;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;

    /* identical to box height */

    /* justo blanco */
    color: #FFFFFF;
}

.container-modal-detele{
    padding-bottom: 10px;
}

.button-cancel{
    cursor: pointer;
    width: 194px;
    height: 50px;

    /* justo verde */
    border: 1px solid #2D4838;
    box-sizing: border-box;
    border-radius: 5px;

    font-family: Lato;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;

    /* identical to box height */

    /* justo verde */
    color: #2D4838;
}

.button-load-file{
    font-family: Lato;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    padding-left: 10px;
    color: #333333;
    background: rgba(2, 70, 56, 0.05);
    width: 100%;
    padding-top: 17px;
    height: 60px;
    background: rgba(2, 70, 56, 0.05);
    border: 1px solid rgba(45, 72, 56, 0.2);
    box-sizing: border-box;
    border-radius: 5px;
    margin-bottom: 50px;
}

.img-button-load{
    border: none;
}

.button-modal-save{
    width: 194px;
    height: 50px;

    /* justo verde */
    background: #2D4838;
    opacity: 0.2;
    border-radius: 5px;

    font-family: Lato;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;

    /* identical to box height */

    /* justo blanco */
    color: #FFFFFF;
}

.button-modal-save-active{
    opacity: 1 !important;
}

.text-load-modal, .img-button-modal {
    display: flex;
    align-items: center;
    padding: 0;
}

.antes-nota{
    margin-bottom: 10px;
}

.nota{
    font-size: 10px;
    color: #333333;
    font-style: italic;
}

.nota strong{
    font-style: normal;
    font-weight: bold;
}

@media only screen and (max-width: 768px) {
    .mobilNone {
        display: none;
    }
    .providers {
        margin-bottom: 91px;
    }
}


.text-item-table-privider{
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    color: #333333;
}
.header-provider{
    margin-left: 80px;
    height: 222px;  
    background: #F2F5F5;
    padding-top: 40px;
}

.header-provider-detail{
    margin-left: 80px;
    height: 169px;
    background: #F2F5F5;
}

.edit-incidences{
    height: 230px !important;
}

.header-provider-detail .title{
    font-family: Lato;
    font-style: normal;
    font-weight: 800;
    font-size: 40px;
    line-height: 48px;

    /* identical to box height */

    /* justo verde */
    color: #2D4838;
    margin-left: 80px;
    padding-top: 28px;
}


.title-view-header-provider{
    font-family: Lato;
    font-style: normal;
    font-weight: 800;
    font-size: 40px;
    line-height: 48px;

    /* identical to box height */

    /* justo verde */
    color: #2D4838;
}

.input-header-search-provider{
    height: 45px;
    padding-left: 20px;
    padding-right: 20px;

    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 22px;

    /* identical to box height */

    /* justo negro */
    color: #333333;
    width: 80%;
    opacity: 0.3;
}


.con-provider { 
    background: #FFFFFF;
    border: 1px solid rgba(45, 72, 56, 0.2);
    box-sizing: border-box;
    border-radius: 5px;
    height: 60px !important;
    
    position:relative; 
    margin-top: 25px !important;
}

.con-provider input { 
    margin:5px 0 0 4px !important; 
    border:0; 
}

.con-provider .MuiSvgIcon-root { 
    display:block; 
    position:absolute; 
    top: 14px !important; 
    right: 20px; 
    cursor:pointer; 
    font-size: 2rem;
    color: gray;
}

.button-header-provider{
    margin-left: 10px;
    margin-top: 29px;
    height: 50px;

    border: 1px solid rgba(45, 72, 56, 0.5);
    box-sizing: border-box;
    border-radius: 5px;
    width: 110px;
    padding: 12px 15px;
}

.button-header-provider span{
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 22px;

    /* identical to box height */

    /* justo negro */
    color: #333333;

    opacity: 0.5;
    padding-right: 12px;
}

.title-header-admin{
    font-family: Lato;
    font-style: normal;
    font-weight: 800;
    font-size: 32px;
    line-height: 48px;
    color: #2D4838;
    margin-left: 30px;
    padding-top: 28px;
}

.button-header-new, .btnDetails{
    margin-top: 29px;
    width: 235px;
    height: 50px;

    /* justo verde */
    background: #2D4838;
    border-radius: 5px;
}

.button-header-new.disable{
    opacity: 0.2;
}

.btnDetails {
    width: 295px;
}

.button-header-new, .btnDetails .text{
    font-family: Lato;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;

    /* identical to box height */

    /* justo blanco */
    color: #FFFFFF;
}

.title-modal-provider{
    font-family: Lato;
    font-style: normal;
    font-weight: 800;
    font-size: 25px;
    line-height: 30px;
    line-height: 19px;
    text-align: center;

    /* justo negro */
    color: #2D4838;
    background-color: #F2F5F5;
    padding: 25px 20px;
    border-radius: 10px;
    margin-bottom: 47px;
}

.title-modal-provider img {
    float: right;
}

.documents-modal-provider {
    display: flex;
    width: 100%;
    justify-content: center;
}

.button-save-modal{
    width: 194px;
    height: 60px;

    /* justo verde */
    background: #2D4838;
    opacity: 0.2;
    border-radius: 5px;
}

.button-save-modal .text, .button-save-modal-active .text{
    font-family: Lato;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;

    /* identical to box height */
    text-align: center;

    /* justo blanco */
    color: #FFFFFF;
}

.container-btn-save {
    margin: 0 auto;
    width: 195px;
}

.button-load-products-modal, .button-load-products-file-modal {
    width: 490px;
}

.rigth{
    float: right;
    margin-left: 20px;
    min-width: 135px;
    cursor: pointer;
}

.button-load-incidence{
    width: 100%;
}

.rigth img {
    opacity: 50%;
}

.button-load-products-file, .button-load-products-file-modal .text-load{
    background: rgba(2, 70, 56, 0.05);
    width: 460px;
}

.select-providers-header{
    width: 300px;
}

.title-header-admin-status {
    margin-left: 30px;
    padding-top: 44px;
}

.text-Title {
    font-family: Lato;
font-style: normal;
font-weight: bold;
font-size: 16px;
line-height: 19px;

/* justo negro */

color: #333333;

opacity: 0.5;
padding: 30px  0 10px 0;

}

@media only screen and (max-width: 470px) {
    .input-header-search-provider{
        padding-left: 10px;
        padding-right: 0;
    }
    
    .button-header-provider span{
        font-size: 14px;
    }
    
    .button-header-new{
        margin-top: 10px;
        width: 206px;
    }
    
    .button-header-new .text{
        font-size: 14px;
    }
    .con-provider {
        margin-top: 0;
    }

    .header-provider{
        height: 100%;
        padding: 20px 0;

    }
    
}
.body-list{
    min-height: 710px;
}

.status-provider-product-admin{
    width: 105px;
    margin-top: 0px;
}
.css-1s2u09g-control, .css-1s2u09g-control, .css-1pahdxg-control{
    height: 60px;
}

.input-select{
    display: block;
}

.input-select .css-1s2u09g-control, .input-select .css-1pahdxg-control{
    height: 100px !important;
}

.input-select-open1 .css-1s2u09g-control, .input-select-open1 .css-1pahdxg-control{
    height: 100px !important;
}
.input-select-open2 .css-1s2u09g-control, .input-select-open2 .css-1pahdxg-control{
    height: 130px !important;
}
.input-select-open3 .css-1s2u09g-control, .input-select-open3 .css-1pahdxg-control{
    height: 160px !important;
}
.input-select-open4 .css-1s2u09g-control, .input-select-open4 .css-1pahdxg-control{
    height: 190px !important;
}
.input-select-open5 .css-1s2u09g-control, .input-select-open5 .css-1pahdxg-control{
    height: 220px !important;
}
.input-select-open6 .css-1s2u09g-control, .input-select-open6 .css-1pahdxg-control{
    height: 250px !important;
}

.title-provider-info{
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;

    /* justo negro */
    color: #333333;

    opacity: 0.5;
}

.value-provider{
    font-family: Lato;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;

    /* identical to box height */

    /* justo negro */
    color: #333333 !important;
}

.icon-load-provider-info{
    padding-top: 17px !important;
}


.icon-user {
    width: 26px;
    height: 26px;
    left: 421px;
    top: 336px;
    object-fit: none;
    /* justo blanco */

    background: #FFFFFF;
    border: 1px solid rgba(51, 51, 51, 0.1);
    border-radius: 50%;
    margin: 10px 10px 0 0;
}

.icon-user img {
    left: 8.6%;
    right: 8.48%;
    top: 8.6%;
    bottom: 8.31%;
}

.content-user {
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    display: flex;
    align-items: center;
    color: #333333;
}

.date-send {
    width: 176px;
    width: 176px;
    opacity: 1;
    font-family: Lato;
    font-style: italic;
    font-weight: normal;
    font-size: 12px;
    padding-top: 10px;
    color: #929494;
}

.close-incidence{
    font-family: Lato;
    font-style: italic;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    color: #929494;
}

.container-message {
    border-top: 1px solid rgba(0, 0, 0, .1);
    padding: 5px;
    margin-top: 2px;
}
.text-details-incidence {
    font-family: Lato;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: left;
    padding: 0 0 0 37px;
    color: #333333;
}

.link-file {
    color: rgba(0,0,0,250,.5);
    font-weight:'500';

}

.text-details-incidence img {
    padding: 32px 0;
}

.content-bg, .content {
    width: 100%;
    height: 167px;
    left: 398px;
    top: 657px;
    padding: 16px 0 0 16px;
    background: #F2F5F5;
    border-top: 1px solid rgb(51 51 51 / 16%);
    border-bottom: 1px solid rgb(51 51 51 / 16%);

}

.content {
    background: #FFFFFF;
    border-top: none;
    border-bottom: none;
    height: 100%;
    min-height: 167px;
}

.container-btn-response {
    padding: 30px 0;
}
.text-item-table-privider{
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    color: #333333;
}
.header-provider{
    margin-left: 80px;
    height: 222px;  
    background: #F2F5F5;
    padding-top: 40px;
}

.header-provider-detail{
    margin-left: 80px;
    padding-bottom: 50px;
    height: 100%;
    background: #F2F5F5;
}

.header-provider-detail .title{
    font-family: Lato;
    font-style: normal;
    font-weight: 800;
    font-size: 40px;
    line-height: 48px;

    /* identical to box height */

    /* justo verde */
    color: #2D4838;
    margin-left: 80px;
    padding-top: 28px;
}


.title-view-header-provider{
    font-family: Lato;
    font-style: normal;
    font-weight: 800;
    font-size: 40px;
    line-height: 48px;

    /* identical to box height */

    /* justo verde */
    color: #2D4838;
}

.input-header-search-provider{
    height: 45px;
    padding-left: 20px;
    padding-right: 20px;

    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 22px;

    /* identical to box height */

    /* justo negro */
    color: #333333;
    width: 80%;
    opacity: 0.3;
}


.con-provider { 
    background: #FFFFFF;
    border: 1px solid rgba(45, 72, 56, 0.2);
    box-sizing: border-box;
    border-radius: 5px;
    height: 50px;
    
    position:relative; 
    margin-top: 29px;
}

.con-provider input { 
    margin:0 0 0 4px; 
    border:0; 
}

.con-provider .MuiSvgIcon-root { 
    display:block; 
    position:absolute; 
    top: 10px; 
    right: 20px; 
    cursor:pointer; 
    font-size: 2rem;
    color: gray;
}

.button-header-provider{
    margin-left: 10px;
    margin-top: 29px;
    height: 50px;

    border: 1px solid rgba(45, 72, 56, 0.5);
    box-sizing: border-box;
    border-radius: 5px;
    width: 110px;
    padding: 12px 15px;
}

.button-header-provider span{
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 22px;

    /* identical to box height */

    /* justo negro */
    color: #333333;

    opacity: 0.5;
    padding-right: 12px;
}

.title-header-admin{
    font-family: Lato;
    font-style: normal;
    font-weight: 800;
    font-size: 32px;
    line-height: 48px;
    color: #2D4838;
    margin-left: 30px;
    padding-top: 28px;
}

.button-header-new, .btnDetails{
    margin-top: 29px;
    width: 235px;
    height: 50px;

    /* justo verde */
    background: #2D4838;
    border-radius: 5px;
}

.button-header-new.disable{
    opacity: 0.2;
}

.btnDetails {
    width: 295px;
}

.button-header-new, .btnDetails .text{
    font-family: Lato;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;

    /* identical to box height */

    /* justo blanco */
    color: #FFFFFF;
}

.title-modal-provider{
    font-family: Lato;
    font-style: normal;
    font-weight: 800;
    font-size: 25px;
    line-height: 30px;
    line-height: 19px;
    text-align: center;

    /* justo negro */
    color: #2D4838;
    background-color: #F2F5F5;
    padding: 25px 20px;
    border-radius: 10px;
    margin-bottom: 47px;
}

.title-modal-provider img {
    float: right;
}

.documents-modal-provider {
    display: flex;
    width: 100%;
    justify-content: center;
}

.button-save-modal{
    width: 194px;
    height: 60px;

    /* justo verde */
    background: #2D4838;
    opacity: 0.2;
    border-radius: 5px;
}

.button-save-modal .text, .button-save-modal-active .text{
    font-family: Lato;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;

    /* identical to box height */
    text-align: center;

    /* justo blanco */
    color: #FFFFFF;
}

.container-btn-save {
    margin: 0 auto;
    width: 195px;
}

.button-load-products-modal, .button-load-products-file-modal {
    width: 490px;
}

.rigth{
    float: right;
    margin-left: 20px;
    min-width: 135px;
    cursor: pointer;
}

.button-load-incidence{
    width: 100%;
}

.rigth img {
    opacity: 50%;
}

.button-load-products-file, .button-load-products-file-modal .text-load{
    background: rgba(2, 70, 56, 0.05);
    width: 460px;
}

.select-providers-header{
    width: 300px;
}

@media only screen and (max-width: 470px) {
    .input-header-search-provider{
        padding-left: 10px;
        padding-right: 0;
    }
    
    .button-header-provider span{
        font-size: 14px;
    }
    
    .button-header-new{
        margin-top: 10px;
        width: 206px;
    }
    
    .button-header-new .text{
        font-size: 14px;
    }
    .con-provider {
        margin-top: 0;
    }

    .header-provider{
        height: 100%;
        padding: 20px 0;

    }
    
}

.icon-user {
    width: 26px;
    height: 26px;
    left: 421px;
    top: 336px;
    object-fit: none;
    /* justo blanco */

    background: #FFFFFF;
    border: 1px solid rgba(51, 51, 51, 0.1);
    border-radius: 50%;
    margin: 10px 10px 0 0;
}

.icon-user img {
    left: 8.6%;
    right: 8.48%;
    top: 8.6%;
    bottom: 8.31%;
}

.content-user {
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    display: flex;
    align-items: center;
    color: #333333;
}

.date-send {
    width: 176px;
    width: 176px;
    opacity: 1;
    font-family: Lato;
    font-style: italic;
    font-weight: normal;
    font-size: 12px;
    padding-top: 10px;
    color: #929494;
}

.container-message {
    border-top: 1px solid rgba(0, 0, 0, .1);
    padding: 5px;
    margin-top: 2px;
}
.text-details-incidence {
    font-family: Lato;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: left;
    padding: 0 0 0 37px;
    color: #333333;
}

.link-file {
    color: rgba(0,0,0,250,.5);
    font-weight:'500';

}

.text-details-incidence img {
    padding: 32px 0;
}

.content-bg, .content {
    width: 100%;
    height: 167px;
    left: 398px;
    top: 657px;
    padding: 16px 0 0 16px;
    background: #F2F5F5;
    border-top: 1px solid rgb(51 51 51 / 16%);
    border-bottom: 1px solid rgb(51 51 51 / 16%);

}

.content {
    background: #FFFFFF;
    border-top: none;
    border-bottom: none;
    height: 100%;
    min-height: 167px;
}

.container-btn-response {
    padding: 30px 0;
}
.text-item-table-privider{
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    color: #333333;
}
.text-item-table-privider{
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    color: #333333;
}
.header-provider{
    margin-left: 80px;
    height: 222px;  
    background: #F2F5F5;
    padding-top: 40px;
}

.header-provider-detail{
    margin-left: 80px;
    padding-bottom: 50px;
    height: 100%;
    background: #F2F5F5;
}

.header-provider-detail .title{
    font-family: Lato;
    font-style: normal;
    font-weight: 800;
    font-size: 40px;
    line-height: 48px;

    /* identical to box height */

    /* justo verde */
    color: #2D4838;
    margin-left: 80px;
    padding-top: 28px;
}


.title-view-header-provider{
    font-family: Lato;
    font-style: normal;
    font-weight: 800;
    font-size: 40px;
    line-height: 48px;

    /* identical to box height */

    /* justo verde */
    color: #2D4838;
}

.input-header-search-provider{
    height: 45px;
    padding-left: 20px;
    padding-right: 20px;

    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 22px;

    /* identical to box height */

    /* justo negro */
    color: #333333;
    width: 80%;
    opacity: 0.3;
}


.con-provider { 
    background: #FFFFFF;
    border: 1px solid rgba(45, 72, 56, 0.2);
    box-sizing: border-box;
    border-radius: 5px;
    height: 50px;
    
    position:relative; 
    margin-top: 29px;
}

.con-provider input { 
    margin:0 0 0 4px; 
    border:0; 
}

.con-provider .MuiSvgIcon-root { 
    display:block; 
    position:absolute; 
    top: 10px; 
    right: 20px; 
    cursor:pointer; 
    font-size: 2rem;
    color: gray;
}

.button-header-provider{
    margin-left: 10px;
    margin-top: 29px;
    height: 50px;

    border: 1px solid rgba(45, 72, 56, 0.5);
    box-sizing: border-box;
    border-radius: 5px;
    width: 110px;
    padding: 12px 15px;
}

.button-header-provider span{
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 22px;

    /* identical to box height */

    /* justo negro */
    color: #333333;

    opacity: 0.5;
    padding-right: 12px;
}

.title-header-admin{
    font-family: Lato;
    font-style: normal;
    font-weight: 800;
    font-size: 32px;
    line-height: 48px;
    color: #2D4838;
    margin-left: 30px;
    padding-top: 28px;
}

.button-header-new, .btnDetails{
    margin-top: 29px;
    width: 235px;
    height: 50px;

    /* justo verde */
    background: #2D4838;
    border-radius: 5px;
}

.button-header-new.disable{
    opacity: 0.2;
}

.btnDetails {
    width: 295px;
}

.button-header-new, .btnDetails .text{
    font-family: Lato;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;

    /* identical to box height */

    /* justo blanco */
    color: #FFFFFF;
}

.title-modal-provider{
    font-family: Lato;
    font-style: normal;
    font-weight: 800;
    font-size: 25px;
    line-height: 30px;
    line-height: 19px;
    text-align: center;

    /* justo negro */
    color: #2D4838;
    background-color: #F2F5F5;
    padding: 25px 20px;
    border-radius: 10px;
    margin-bottom: 47px;
}

.title-modal-provider img {
    float: right;
}

.documents-modal-provider {
    display: flex;
    width: 100%;
    justify-content: center;
}

.button-save-modal{
    width: 194px;
    height: 60px;

    /* justo verde */
    background: #2D4838;
    opacity: 0.2;
    border-radius: 5px;
}

.button-save-modal .text, .button-save-modal-active .text{
    font-family: Lato;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;

    /* identical to box height */
    text-align: center;

    /* justo blanco */
    color: #FFFFFF;
}

.container-btn-save {
    margin: 0 auto;
    width: 195px;
}

.button-load-products-modal, .button-load-products-file-modal {
    width: 490px;
}

.rigth{
    float: right;
    margin-left: 20px;
    min-width: 135px;
    cursor: pointer;
}

.button-load-incidence{
    width: 100%;
}

.rigth img {
    opacity: 50%;
}

.button-load-products-file, .button-load-products-file-modal .text-load{
    background: rgba(2, 70, 56, 0.05);
    width: 460px;
}

.select-providers-header{
    width: 300px;
}

@media only screen and (max-width: 470px) {
    .input-header-search-provider{
        padding-left: 10px;
        padding-right: 0;
    }
    
    .button-header-provider span{
        font-size: 14px;
    }
    
    .button-header-new{
        margin-top: 10px;
        width: 206px;
    }
    
    .button-header-new .text{
        font-size: 14px;
    }
    .con-provider {
        margin-top: 0;
    }

    .header-provider{
        height: 100%;
        padding: 20px 0;

    }
    
}
.header-provider{
    margin-left: 80px;
    height: 222px;
    background: #F2F5F5;
    padding-top: 40px;
}

.header-provider-detail{
    margin-left: 80px;
    height: 169px;
    background: #F2F5F5;
}

.header-provider-detail .title{
    font-family: Lato;
    font-style: normal;
    font-weight: 800;
    font-size: 40px;
    line-height: 48px;

    /* identical to box height */

    /* justo verde */
    color: #2D4838;
    margin-left: 80px;
    padding-top: 28px;
}


.title-view-header-provider{
    font-family: Lato;
    font-style: normal;
    font-weight: 800;
    font-size: 40px;
    line-height: 48px;

    /* identical to box height */

    /* justo verde */
    color: #2D4838;
}

.title-view-header-provider-detail{
    padding-left: 80px;
    padding-top: 27px;
}
.subtitle-view-header-provider-detail{
    padding-left: 80px;
    color:rgba(51, 51, 51,.7)
}

.input-header-search-provider{
    height: 45px;
    padding-left: 20px;
    padding-right: 20px;

    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 22px;

    /* identical to box height */

    /* justo negro */
    color: #333333;
    width: 80%;
    opacity: 0.3;
}


.con-provider {
    background: #FFFFFF;
    border: 1px solid rgba(45, 72, 56, 0.2);
    box-sizing: border-box;
    border-radius: 5px;
    height: 50px;

    position:relative;
    margin-top: 29px;
}

.con-provider input {
    margin:0 0 0 4px;
    border:0;
}

.con-provider .MuiSvgIcon-root {
    display:block;
    position:absolute;
    top: 10px;
    right: 20px;
    cursor:pointer;
    font-size: 2rem;
    color: gray;
}

.button-header-provider{
    margin-left: 10px;
    margin-top: 29px;
    height: 50px;

    border: 1px solid rgba(45, 72, 56, 0.5);
    box-sizing: border-box;
    border-radius: 5px;
    width: 110px;
    padding: 12px 15px;
}

.button-header-provider span{
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 22px;

    /* identical to box height */

    /* justo negro */
    color: #333333;

    opacity: 0.5;
    padding-right: 12px;
}

.button-header-new, .btnDetails{
    margin-top: 29px;
    width: 235px;
    height: 50px;

    /* justo verde */
    background: #2D4838;
    border-radius: 5px;
}

.btnDetails {
    width: 295px;
}

.button-header-new, .btnDetails .text{
    font-family: Lato;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;

    /* identical to box height */

    /* justo blanco */
    color: #FFFFFF;
}

.title-modal-provider{
    font-family: Lato;
    font-style: normal;
    font-weight: 800;
    font-size: 25px;
    line-height: 30px;
    line-height: 19px;
    text-align: center;

    /* justo negro */
    color: #2D4838;
    background-color: #F2F5F5;
    padding: 25px 20px;
    border-radius: 10px;
    margin-bottom: 47px;
}

.title-modal-provider img {
    float: right;
}

.documents-modal-provider {
    display: flex;
    width: 100%;
    justify-content: center;
}

.button-save-modal{
    width: 194px;
    height: 60px;

    /* justo verde */
    background: #2D4838;
    opacity: 0.2;
    border-radius: 5px;
}

.button-save-modal .text, .button-save-modal-active, .button-save-incidence .text{
    font-family: Lato;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;

    /* identical to box height */
    text-align: center;

    /* justo blanco */
    color: #FFFFFF;
}

.container-btn-save {
    margin: 0 auto;
    width: 195px;
}

.button-load-products-modal, .button-load-products-file-modal {
    width: 490px;
}

.rigth{
    float: right;
    margin-left: 20px;
    min-width: 135px;
    cursor: pointer;
}

.button-load-incidence{
    width: 100%;
}

.rigth img {
    opacity: 50%;
}

.button-load-products-file, .button-load-products-file-modal .text-load{
    background: rgba(2, 70, 56, 0.05);
    width: 100%;
}

@media only screen and (max-width: 470px) {
    .input-header-search-provider{
        padding-left: 10px;
        padding-right: 0;
    }

    .button-header-provider span{
        font-size: 14px;
    }

    .button-header-new{
        margin-top: 10px;
        width: 206px;
    }

    .button-header-new .text{
        font-size: 14px;
    }
    .con-provider {
        margin-top: 0;
    }

    .header-provider{
        height: 100%;
        padding: 20px 0;

    }

}

.textarea{
    height: 111px;
    padding: 10px;
}

.button-save-form-product{
    margin-top: 60px;
}
.icon-user {
    width: 26px;
    height: 26px;
    left: 421px;
    top: 336px;
    object-fit: none;
    /* justo blanco */

    background: #FFFFFF;
    border: 1px solid rgba(51, 51, 51, 0.1);
    border-radius: 50%;
    margin: 10px 10px 0 0;
}

.icon-user img {
    left: 8.6%;
    right: 8.48%;
    top: 8.6%;
    bottom: 8.31%;
}

.content-user {
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    display: flex;
    align-items: center;
    color: #333333;
}

.date-send {
    width: 176px;
    width: 176px;
    opacity: 1;
    font-family: Lato;
    font-style: italic;
    font-weight: normal;
    font-size: 12px;
    padding-top: 10px;
    color: #929494;
}

.container-message {
    border-top: 1px solid rgba(0, 0, 0, .1);
    padding: 5px;
    margin-top: 2px;
}
.text-details-incidence {
    font-family: Lato;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: left;
    padding: 0 0 0 37px;
    color: #333333;
}

.link-file {
    color: rgba(0,0,0,250,.5);
    font-weight:'500';

}

.text-details-incidence img {
    padding: 32px 0;
}

.content-bg, .content {
    width: 100%;
    height: 167px;
    left: 398px;
    top: 657px;
    padding: 16px 0 0 16px;
    background: #F2F5F5;
    border-top: 1px solid rgb(51 51 51 / 16%);
    border-bottom: 1px solid rgb(51 51 51 / 16%);

}

.content {
    background: #FFFFFF;
    border-top: none;
    border-bottom: none;
    height: 100%;
    min-height: 167px;
}

.container-btn-response {
    padding: 30px 0;
}
.text-item-table-privider{
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    color: #333333;
}
.title-table-orders{
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 12px;

    /* identical to box height */
    text-transform: uppercase;

    /* justo verde */
    color: #2D4838;
}

.icon-export-provider{
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    text-align: center;

    /* justo negro */
    color: #333333;
    float: left;
    padding-right: 5px;
    padding-left: 5px;
    margin-top: -8px;
}

.icon-export-provider a{
    color: #333333;
    font-size: 12px;
    line-height: 14px;
}

.icon-export-provider a:hover{
    text-decoration: none;
}


.icon-export-provider.empty{
    background: #e0e4e2;
}

.empty img{
    padding-top: 5px;
    padding-bottom: 3px;
}

.icon-action-provider{
    padding-right: 10px;
    padding-left: 10px;
    float: left;
}

.body-provider{
    margin-left: 80px;
    padding-bottom: 50px;
}

.status-orders{
    width: 123px !important;
    height: 29px !important;
}

.btnAddProduct{
    margin-top: 29px;
    width: 165px;
    height: 50px;

    border: 1px solid rgba(45, 72, 56, 0.5);
    box-sizing: border-box;
    border-radius: 5px;

    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 22px;
    color: #333333;
    background: white;
}

.total-orders{
    padding: 10px;
    width: 100%;
    text-align: right;
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: 10px;
    line-height: 12px;

    /* identical to box height */
    text-transform: uppercase;

    /* justo verde */
    color: #2D4838;
}

.total-orders span{
    padding-left: 30px;
    padding-right: 10px;
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 22px;

    /* identical to box height */

    /* justo negro */
    color: #333333;
}

.created_at{
    padding-left: 15px;
}

.icon-remove{
    margin-top: -1px;
}

.item-actions{
    float: right;
}

.text-center{
    text-align: center;
}

.container-select-day {
    margin: 0 auto;
    width: 98%;
}

.item-privider {
    font-family: Lato;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    color: #333333;
    padding-bottom: 30PX;
}

.label-order {
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;
    color: #333333;
    padding-bottom: 10px;

}

.container-modal-btn-schedule {
    width: 100%;
    margin-top: 40px;
}
.iframe-reports{
    border: none;
    width: 100%;
    padding-bottom: 30px;
    height: 760px;
}

.error-reports{
    border: none;
    width: 100%;
    padding-bottom: 30px;
    padding-top: 100px;
    height: 760px;
    text-align: center;
}

.text-error{
    font-family: Lato;
    font-style: normal;
    font-weight: 800;
    font-size: 40px;
    line-height: 48px;
    color: #2D4838;
}
.header-provider{
    margin-left: 80px;
    height: 222px;  
    background: #F2F5F5;
    padding-top: 40px;
}

.header-provider-detail{
    margin-left: 80px;
    height: 169px;
    background: #F2F5F5;
}

.header-provider-detail .title{
    font-family: Lato;
    font-style: normal;
    font-weight: 800;
    font-size: 40px;
    line-height: 48px;

    /* identical to box height */

    /* justo verde */
    color: #2D4838;
    margin-left: 80px;
    padding-top: 28px;
}


.title-view-header-provider{
    font-family: Lato;
    font-style: normal;
    font-weight: 800;
    font-size: 40px;
    line-height: 48px;

    /* identical to box height */

    /* justo verde */
    color: #2D4838;
}

.input-header-search-provider{
    height: 45px;
    padding-left: 20px;
    padding-right: 20px;

    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 22px;

    /* identical to box height */

    /* justo negro */
    color: #333333;
    width: 80%;
    opacity: 0.3;
}


.con-provider { 
    background: #FFFFFF;
    border: 1px solid rgba(45, 72, 56, 0.2);
    box-sizing: border-box;
    border-radius: 5px;
    height: 50px;
    
    position:relative; 
    margin-top: 29px;
}

.con-provider input { 
    margin:0 0 0 4px; 
    border:0; 
}

.con-provider .MuiSvgIcon-root { 
    display:block; 
    position:absolute; 
    top: 10px; 
    right: 20px; 
    cursor:pointer; 
    font-size: 2rem;
    color: gray;
}

.button-header-provider{
    margin-left: 10px;
    margin-top: 29px;
    height: 50px;

    border: 1px solid rgba(45, 72, 56, 0.5);
    box-sizing: border-box;
    border-radius: 5px;
    width: 110px;
    padding: 12px 15px;
}

.button-header-provider span{
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 22px;

    /* identical to box height */

    /* justo negro */
    color: #333333;

    opacity: 0.5;
    padding-right: 12px;
}

.title-header-admin{
    font-family: Lato;
    font-style: normal;
    font-weight: 800;
    font-size: 32px;
    line-height: 48px;
    color: #2D4838;
    margin-left: 30px;
    padding-top: 28px;
}

.button-header-new, .btnDetails{
    margin-top: 29px;
    width: 235px;
    height: 50px;

    /* justo verde */
    background: #2D4838;
    border-radius: 5px;
}

.button-header-new.disable{
    opacity: 0.2;
}

.btnDetails {
    width: 295px;
}

.button-header-new, .btnDetails .text{
    font-family: Lato;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;

    /* identical to box height */

    /* justo blanco */
    color: #FFFFFF;
}

.title-modal-provider{
    font-family: Lato;
    font-style: normal;
    font-weight: 800;
    font-size: 25px;
    line-height: 30px;
    line-height: 19px;
    text-align: center;

    /* justo negro */
    color: #2D4838;
    background-color: #F2F5F5;
    padding: 25px 20px;
    border-radius: 10px;
    margin-bottom: 47px;
}

.title-modal-provider img {
    float: right;
}

.documents-modal-provider {
    display: flex;
    width: 100%;
    justify-content: center;
}

.button-save-modal{
    width: 194px;
    height: 60px;

    /* justo verde */
    background: #2D4838;
    opacity: 0.2;
    border-radius: 5px;
}

.button-save-modal .text, .button-save-modal-active .text{
    font-family: Lato;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;

    /* identical to box height */
    text-align: center;

    /* justo blanco */
    color: #FFFFFF;
}

.container-btn-save {
    margin: 0 auto;
    width: 195px;
}

.button-load-products-modal, .button-load-products-file-modal {
    width: 490px;
}

.rigth{
    float: right;
    margin-left: 20px;
    min-width: 135px;
    cursor: pointer;
}

.button-load-incidence{
    width: 100%;
}

.rigth img {
    opacity: 50%;
}

.button-load-products-file, .button-load-products-file-modal .text-load{
    background: rgba(2, 70, 56, 0.05);
    width: 460px;
}

.select-providers-header{
    width: 300px;
}

@media only screen and (max-width: 470px) {
    .input-header-search-provider{
        padding-left: 10px;
        padding-right: 0;
    }
    
    .button-header-provider span{
        font-size: 14px;
    }
    
    .button-header-new{
        margin-top: 10px;
        width: 206px;
    }
    
    .button-header-new .text{
        font-size: 14px;
    }
    .con-provider {
        margin-top: 0;
    }

    .header-provider{
        height: 100%;
        padding: 20px 0;

    }
    
}

.icon-user {
    width: 26px;
    height: 26px;
    left: 421px;
    top: 336px;
    object-fit: none;
    /* justo blanco */

    background: #FFFFFF;
    border: 1px solid rgba(51, 51, 51, 0.1);
    border-radius: 50%;
    margin: 10px 10px 0 0;
}

.icon-user img {
    left: 8.6%;
    right: 8.48%;
    top: 8.6%;
    bottom: 8.31%;
}

.content-user {
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    display: flex;
    align-items: center;
    color: #333333;
}

.date-send {
    width: 176px;
    width: 176px;
    opacity: 1;
    font-family: Lato;
    font-style: italic;
    font-weight: normal;
    font-size: 12px;
    padding-top: 10px;
    color: #929494;
}

.container-message {
    border-top: 1px solid rgba(0, 0, 0, .1);
    padding: 5px;
    margin-top: 2px;
}
.text-details-incidence {
    font-family: Lato;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: left;
    padding: 0 0 0 37px;
    color: #333333;
}

.link-file {
    color: rgba(0,0,0,250,.5);
    font-weight:'500';

}

.text-details-incidence img {
    padding: 32px 0;
}

.content-bg, .content {
    width: 100%;
    height: 167px;
    left: 398px;
    top: 657px;
    padding: 16px 0 0 16px;
    background: #F2F5F5;
    border-top: 1px solid rgb(51 51 51 / 16%);
    border-bottom: 1px solid rgb(51 51 51 / 16%);

}

.content {
    background: #FFFFFF;
    border-top: none;
    border-bottom: none;
    height: 100%;
    min-height: 167px;
}

.container-btn-response {
    padding: 30px 0;
}
.text-item-table-privider{
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    color: #333333;
}
.title-table-orders{
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 12px;

    /* identical to box height */
    text-transform: uppercase;

    /* justo verde */
    color: #2D4838;
}

.icon-export-provider{
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    text-align: center;

    /* justo negro */
    color: #333333;
    float: left;
    padding-right: 5px;
    padding-left: 5px;
    margin-top: -8px;
}

.icon-export-provider a{
    color: #333333;
    font-size: 12px;
    line-height: 14px;
}

.icon-export-provider a:hover{
    text-decoration: none;
}


.icon-export-provider.empty{
    background: #e0e4e2;
}

.empty img{
    padding-top: 5px;
    padding-bottom: 3px;
}

.icon-action-provider{
    padding-right: 10px;
    padding-left: 10px;
    float: left;
}

.body-provider{
    margin-left: 80px;
    padding-bottom: 50px;
}

.status-orders{
    width: 123px !important;
    height: 29px !important;
}

.btnAddProduct{
    margin-top: 29px;
    width: 165px;
    height: 50px;

    border: 1px solid rgba(45, 72, 56, 0.5);
    box-sizing: border-box;
    border-radius: 5px;

    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 22px;
    color: #333333;
    background: white;
}

.total-orders{
    padding: 10px;
    width: 100%;
    text-align: right;
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: 10px;
    line-height: 12px;

    /* identical to box height */
    text-transform: uppercase;

    /* justo verde */
    color: #2D4838;
}

.total-orders span{
    padding-left: 30px;
    padding-right: 10px;
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 22px;

    /* identical to box height */

    /* justo negro */
    color: #333333;
}

.created_at{
    padding-left: 15px;
}

.icon-remove{
    margin-top: -1px;
}

.item-actions{
    float: right;
}

.text-center{
    text-align: center;
}

.container-select-day {
    margin: 0 auto;
    width: 98%;
}

.item-privider {
    font-family: Lato;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    color: #333333;
    padding-bottom: 30PX;
}

.label-order {
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;
    color: #333333;
    padding-bottom: 10px;

}

.container-modal-btn-schedule {
    width: 100%;
    margin-top: 40px;
}
.title-table-orders{
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 12px;

    /* identical to box height */
    text-transform: uppercase;

    /* justo verde */
    color: #2D4838;
}

.icon-export-provider{
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    text-align: center;

    /* justo negro */
    color: #333333;
    float: left;
    padding-right: 5px;
    padding-left: 5px;
    margin-top: -8px;
}

.icon-export-provider a{
    color: #333333;
    font-size: 12px;
    line-height: 14px;
}

.icon-export-provider a:hover{
    text-decoration: none;
}


.icon-export-provider.empty{
    background: #e0e4e2;
}

.empty img{
    padding-top: 5px;
    padding-bottom: 3px;
}

.icon-action-provider{
    padding-right: 10px;
    padding-left: 10px;
    float: left;
}

.body-provider{
    margin-left: 80px;
    padding-bottom: 50px;
}

.status-orders{
    width: 123px !important;
    height: 29px !important;
}

.btnAddProduct{
    margin-top: 29px;
    width: 165px;
    height: 50px;

    border: 1px solid rgba(45, 72, 56, 0.5);
    box-sizing: border-box;
    border-radius: 5px;

    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 22px;
    color: #333333;
    background: white;
}

.total-orders{
    padding: 10px;
    width: 100%;
    text-align: right;
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: 10px;
    line-height: 12px;

    /* identical to box height */
    text-transform: uppercase;

    /* justo verde */
    color: #2D4838;
}

.total-orders span{
    padding-left: 30px;
    padding-right: 10px;
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 22px;

    /* identical to box height */

    /* justo negro */
    color: #333333;
}

.created_at{
    padding-left: 15px;
}

.icon-remove{
    margin-top: -1px;
}

.item-actions{
    float: right;
}

.text-center{
    text-align: center;
}

.container-select-day {
    margin: 0 auto;
    width: 98%;
}

.item-privider {
    font-family: Lato;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    color: #333333;
    padding-bottom: 30PX;
}

.label-order {
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;
    color: #333333;
    padding-bottom: 10px;

}

.container-modal-btn-schedule {
    width: 100%;
    margin-top: 40px;
}
.header-provider{
    margin-left: 80px;
    height: 222px;
    background: #F2F5F5;
    padding-top: 40px;
}

.header-provider-detail{
    margin-left: 80px;
    height: 169px;
    background: #F2F5F5;
}

.header-provider-detail .title{
    font-family: Lato;
    font-style: normal;
    font-weight: 800;
    font-size: 40px;
    line-height: 48px;

    /* identical to box height */

    /* justo verde */
    color: #2D4838;
    margin-left: 80px;
    padding-top: 28px;
}


.title-view-header-provider{
    font-family: Lato;
    font-style: normal;
    font-weight: 800;
    font-size: 40px;
    line-height: 48px;

    /* identical to box height */

    /* justo verde */
    color: #2D4838;
}

.title-view-header-provider-detail{
    padding-left: 80px;
    padding-top: 27px;
}
.subtitle-view-header-provider-detail{
    padding-left: 80px;
    color:rgba(51, 51, 51,.7)
}

.input-header-search-provider{
    height: 45px;
    padding-left: 20px;
    padding-right: 20px;

    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 22px;

    /* identical to box height */

    /* justo negro */
    color: #333333;
    width: 80%;
    opacity: 0.3;
}


.con-provider {
    background: #FFFFFF;
    border: 1px solid rgba(45, 72, 56, 0.2);
    box-sizing: border-box;
    border-radius: 5px;
    height: 50px;

    position:relative;
    margin-top: 29px;
}

.con-provider input {
    margin:0 0 0 4px;
    border:0;
}

.con-provider .MuiSvgIcon-root {
    display:block;
    position:absolute;
    top: 10px;
    right: 20px;
    cursor:pointer;
    font-size: 2rem;
    color: gray;
}

.button-header-provider{
    margin-left: 10px;
    margin-top: 29px;
    height: 50px;

    border: 1px solid rgba(45, 72, 56, 0.5);
    box-sizing: border-box;
    border-radius: 5px;
    width: 110px;
    padding: 12px 15px;
}

.button-header-provider span{
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 22px;

    /* identical to box height */

    /* justo negro */
    color: #333333;

    opacity: 0.5;
    padding-right: 12px;
}

.button-header-new, .btnDetails{
    margin-top: 29px;
    width: 235px;
    height: 50px;

    /* justo verde */
    background: #2D4838;
    border-radius: 5px;
}

.btnDetails {
    width: 295px;
}

.button-header-new, .btnDetails .text{
    font-family: Lato;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;

    /* identical to box height */

    /* justo blanco */
    color: #FFFFFF;
}

.title-modal-provider{
    font-family: Lato;
    font-style: normal;
    font-weight: 800;
    font-size: 25px;
    line-height: 30px;
    line-height: 19px;
    text-align: center;

    /* justo negro */
    color: #2D4838;
    background-color: #F2F5F5;
    padding: 25px 20px;
    border-radius: 10px;
    margin-bottom: 47px;
}

.title-modal-provider img {
    float: right;
}

.documents-modal-provider {
    display: flex;
    width: 100%;
    justify-content: center;
}

.button-save-modal{
    width: 194px;
    height: 60px;

    /* justo verde */
    background: #2D4838;
    opacity: 0.2;
    border-radius: 5px;
}

.button-save-modal .text, .button-save-modal-active, .button-save-incidence .text{
    font-family: Lato;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;

    /* identical to box height */
    text-align: center;

    /* justo blanco */
    color: #FFFFFF;
}

.container-btn-save {
    margin: 0 auto;
    width: 195px;
}

.button-load-products-modal, .button-load-products-file-modal {
    width: 490px;
}

.rigth{
    float: right;
    margin-left: 20px;
    min-width: 135px;
    cursor: pointer;
}

.button-load-incidence{
    width: 100%;
}

.rigth img {
    opacity: 50%;
}

.button-load-products-file, .button-load-products-file-modal .text-load{
    background: rgba(2, 70, 56, 0.05);
    width: 100%;
}

@media only screen and (max-width: 470px) {
    .input-header-search-provider{
        padding-left: 10px;
        padding-right: 0;
    }

    .button-header-provider span{
        font-size: 14px;
    }

    .button-header-new{
        margin-top: 10px;
        width: 206px;
    }

    .button-header-new .text{
        font-size: 14px;
    }
    .con-provider {
        margin-top: 0;
    }

    .header-provider{
        height: 100%;
        padding: 20px 0;

    }

}
.text-item-table-privider{
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    color: #333333;
}

.icon-user {
    width: 26px;
    height: 26px;
    left: 421px;
    top: 336px;
    object-fit: none;
    /* justo blanco */

    background: #FFFFFF;
    border: 1px solid rgba(51, 51, 51, 0.1);
    border-radius: 50%;
    margin: 10px 10px 0 0;
}

.icon-user img {
    left: 8.6%;
    right: 8.48%;
    top: 8.6%;
    bottom: 8.31%;
}

.content-user {
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    display: flex;
    align-items: center;
    color: #333333;
}

.date-send {
    width: 176px;
    width: 176px;
    opacity: 1;
    font-family: Lato;
    font-style: italic;
    font-weight: normal;
    font-size: 12px;
    padding-top: 10px;
    color: #929494;
}

.container-message {
    border-top: 1px solid rgba(0, 0, 0, .1);
    padding: 5px;
    margin-top: 2px;
}
.text-details-incidence {
    font-family: Lato;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: left;
    padding: 0 0 0 37px;
    color: #333333;
}

.link-file {
    color: rgba(0,0,0,250,.5);
    font-weight:'500';

}

.text-details-incidence img {
    padding: 32px 0;
}

.content-bg, .content {
    width: 100%;
    height: 167px;
    left: 398px;
    top: 657px;
    padding: 16px 0 0 16px;
    background: #F2F5F5;
    border-top: 1px solid rgb(51 51 51 / 16%);
    border-bottom: 1px solid rgb(51 51 51 / 16%);

}

.content {
    background: #FFFFFF;
    border-top: none;
    border-bottom: none;
    height: 100%;
    min-height: 167px;
}

.container-btn-response {
    padding: 30px 0;
}
.text-new-incidence{
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;

    /* justo negro */
    color: #333333;
    padding-top: 37px;
}

.text-new-incidence-not-first{
    padding-bottom: 37px;
}

.button-load-file-form{
    margin-bottom: 0px;
    background: rgba(2, 70, 56, 0.05);
}

.text-end{
    text-align: center;
}

.label-form{
    height: 25px;
}

.text-area-form{
    height: 109px;
    padding: 10px;
}

#selectFileOC,
#selectFileEvidence{
    width: auto !important;
    display: none;
}

.button-save-incidence{
    width: 194px;
    height: 60px;

    /* justo verde */
    background: #2D4838;
    opacity: 0.2;
    border-radius: 5px;
}

.button-load-incidence {
    width: 100%;
}

.button-save-active{
    opacity: 1;
}
.textarea{
    height: 111px;
    padding: 10px;
}

.button-save-form-product{
    margin-top: 60px;
}
.title-table-orders{
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 12px;

    /* identical to box height */
    text-transform: uppercase;

    /* justo verde */
    color: #2D4838;
}

.icon-export-provider{
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    text-align: center;

    /* justo negro */
    color: #333333;
    float: left;
    padding-right: 5px;
    padding-left: 5px;
    margin-top: -8px;
}

.icon-export-provider a{
    color: #333333;
    font-size: 12px;
    line-height: 14px;
}

.icon-export-provider a:hover{
    text-decoration: none;
}


.icon-export-provider.empty{
    background: #e0e4e2;
}

.empty img{
    padding-top: 5px;
    padding-bottom: 3px;
}

.icon-action-provider{
    padding-right: 10px;
    padding-left: 10px;
    float: left;
}

.body-provider{
    margin-left: 80px;
    padding-bottom: 50px;
}

.status-orders{
    width: 123px !important;
    height: 29px !important;
}

.btnAddProduct{
    margin-top: 29px;
    width: 165px;
    height: 50px;

    border: 1px solid rgba(45, 72, 56, 0.5);
    box-sizing: border-box;
    border-radius: 5px;

    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 22px;
    color: #333333;
    background: white;
}

.total-orders{
    padding: 10px;
    width: 100%;
    text-align: right;
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: 10px;
    line-height: 12px;

    /* identical to box height */
    text-transform: uppercase;

    /* justo verde */
    color: #2D4838;
}

.total-orders span{
    padding-left: 30px;
    padding-right: 10px;
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 22px;

    /* identical to box height */

    /* justo negro */
    color: #333333;
}

.created_at{
    padding-left: 15px;
}

.icon-remove{
    margin-top: -1px;
}

.item-actions{
    float: right;
}

.text-center{
    text-align: center;
}

.container-select-day {
    margin: 0 auto;
    width: 98%;
}

.item-privider {
    font-family: Lato;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    color: #333333;
    padding-bottom: 30px;
}

.label-order {
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;
    color: #333333;
    padding-bottom: 10px;

}

.container-modal-btn-schedule {
    width: 100%;
    margin-top: 40px;
}
.header-provider{
    margin-left: 80px;
    height: 222px;
    background: #F2F5F5;
    padding-top: 40px;
}

.header-provider-detail{
    margin-left: 80px;
    height: 169px;
    background: #F2F5F5;
}

.header-provider-detail .title{
    font-family: Lato;
    font-style: normal;
    font-weight: 800;
    font-size: 40px;
    line-height: 48px;

    /* identical to box height */

    /* justo verde */
    color: #2D4838;
    margin-left: 80px;
    padding-top: 28px;
}


.title-view-header-provider{
    font-family: Lato;
    font-style: normal;
    font-weight: 800;
    font-size: 40px;
    line-height: 48px;

    /* identical to box height */

    /* justo verde */
    color: #2D4838;
}

.title-view-header-provider-detail{
    padding-left: 20px;
    padding-top: 27px;
}
.subtitle-view-header-provider-detail{
    padding-left: 20px;
    color:rgba(51, 51, 51,.7)
}

.input-header-search-provider{
    height: 45px;
    padding-left: 20px;
    padding-right: 20px;

    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 22px;

    /* identical to box height */

    /* justo negro */
    color: #333333;
    width: 80%;
    opacity: 0.3;
}


.con-provider {
    background: #FFFFFF;
    border: 1px solid rgba(45, 72, 56, 0.2);
    box-sizing: border-box;
    border-radius: 5px;
    height: 50px;

    position:relative;
    margin-top: 29px;
}

.con-provider input {
    margin:0 0 0 4px;
    border:0;
}

.con-provider .MuiSvgIcon-root {
    display:block;
    position:absolute;
    top: 10px;
    right: 20px;
    cursor:pointer;
    font-size: 2rem;
    color: gray;
}

.button-header-provider{
    margin-left: 10px;
    margin-top: 29px;
    height: 50px;

    border: 1px solid rgba(45, 72, 56, 0.5);
    box-sizing: border-box;
    border-radius: 5px;
    width: 110px;
    padding: 12px 15px;
}

.button-header-provider span{
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 22px;

    /* identical to box height */

    /* justo negro */
    color: #333333;

    opacity: 0.5;
    padding-right: 12px;
}

.button-header-new, .btnDetails{
    margin-top: 29px;
    width: 235px;
    height: 50px;

    /* justo verde */
    background: #2D4838;
    border-radius: 5px;
}

.btnDetails {
    width: 295px;
}

.button-header-new, .btnDetails .text{
    font-family: Lato;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;

    /* identical to box height */

    /* justo blanco */
    color: #FFFFFF;
}

.title-modal-provider{
    font-family: Lato;
    font-style: normal;
    font-weight: 800;
    font-size: 25px;
    line-height: 30px;
    line-height: 19px;
    text-align: center;

    /* justo negro */
    color: #2D4838;
    background-color: #F2F5F5;
    padding: 25px 20px;
    border-radius: 10px;
    margin-bottom: 47px;
}

.title-modal-provider img {
    float: right;
}

.documents-modal-provider {
    display: flex;
    width: 100%;
    justify-content: center;
}

.button-save-modal{
    width: 194px;
    height: 60px;

    /* justo verde */
    background: #2D4838;
    opacity: 0.2;
    border-radius: 5px;
}

.button-save-modal .text, .button-save-modal-active, .button-save-incidence .text{
    font-family: Lato;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;

    /* identical to box height */
    text-align: center;

    /* justo blanco */
    color: #FFFFFF;
}

.container-btn-save {
    margin: 0 auto;
    width: 195px;
}

.button-load-products-modal, .button-load-products-file-modal {
    width: 490px;
}

.rigth{
    float: right;
    margin-left: 20px;
    min-width: 135px;
    cursor: pointer;
}

.button-load-incidence{
    width: 100%;
}

.rigth img {
    opacity: 50%;
}

.button-load-products-file, .button-load-products-file-modal .text-load{
    background: rgba(2, 70, 56, 0.05);
    width: 100%;
}

@media only screen and (max-width: 470px) {
    .input-header-search-provider{
        padding-left: 10px;
        padding-right: 0;
    }

    .button-header-provider span{
        font-size: 14px;
    }

    .button-header-new{
        margin-top: 10px;
        width: 206px;
    }

    .button-header-new .text{
        font-size: 14px;
    }
    .con-provider {
        margin-top: 0;
    }

    .header-provider{
        height: 100%;
        padding: 20px 0;

    }

}
.ReactModalPortal > div {
  opacity: 0;
}

button {
  cursor: pointer;
}

.ReactModalPortal .ReactModal__Overlay {
  align-items: start !important;
  margin-top: 5%;
  display: flex;
  justify-content: center;
  transition: opacity 0.2s ease-in-out;
  z-index: 999;
}

.modal-fondo-calendar {
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  position: fixed;
  background: none;
}

.ReactModalPortal .ReactModal__Overlay--after-open {
  opacity: 1;
}

.ReactModalPortal .ReactModal__Overlay--before-close {
  opacity: 0;
}

.rbc-date-cell {
  text-align: center;
  font-size: 12px;
  height: 10px;
}
.rbc-date-cell > a{
  padding: 3px;
  text-align: center !important;
}

.rbc-toolbar .rbc-toolbar-label {
  text-align: start !important;
  text-transform: capitalize;
  border: none !important;
  padding-top: 10px !important;
  margin-bottom: 20px;
  color: #2D4838;
  font-weight: 900;
  font-size: 28px;
}

.btn-calendar-prev {
  visibility: hidden;
}
.btn-calendar-prev:before {
  content: "<";
  visibility: visible;
  font-size: 30px;
  cursor: pointer;
}

.btn-calendar-next {
  visibility: hidden;
}
.btn-calendar-next:before {
  content: ">";
  visibility: visible;
  font-size: 30px;
  cursor: pointer;
}
.view-active {
  color: #2D4838;
}

.rbc-month-view {
  border: none !important;
  align-self: center !important;
  width: 95% !important;
}
.rbc-day-bg + .rbc-day-bg {
  border: none !important;
}
.rbc-month-row + .rbc-month-row {
  border: none !important;
}

.rbc-time-view {
  border: none !important;
}


.rbc-month-header {
  margin-bottom: 20px;
  font-weight: normal;
}
.rbc-header, button.react-calendar__navigation__label > span {
  border: none !important;
  text-transform: capitalize;
  color: rgba(37, 37, 37, .5);
  font-style: normal;
  font-weight: normal;
}
.rbc-today {
  background-color: white;
}

.rbc-header {
  border: none !important;
}

div.table-cont > div > div.rbc-toolbar > span:nth-child(1) > button:nth-child(1) {
  display: none;
}
body > div.ReactModalPortal > div > div > form > div > div > select {
  padding-right: 2.5em;
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  display: flex;
  align-items: center;
  color: #a5a5a5;
}

/* button.react-datetime-picker__clear-button.react-datetime-picker__button {
  display: none !important;
} */

button.react-datetime-picker__calendar-button.react-datetime-picker__button > svg > rect {
  stroke: #a5a5a5;
}
button.react-datetime-picker__calendar-button.react-datetime-picker__button > svg > line {
  stroke: #a5a5a5;
}
.select:not(.is-multiple):not(.is-loading)::after {
  border-color: #A5A5A5;
  right: 1.125em;
  z-index: 0;
}

.react-datetime-picker >.react-calendar__month-view__weekdays {
  color: #002985 !important;
}

div.react-calendar__viewContainer > div > div > div > div.react-calendar__month-view__weekdays{
  color: #002985;
  font-size: 10px;
}
div.react-calendar__viewContainer > div > div > div > div.react-calendar__month-view__weekdays > div > abbr{
  text-decoration: none;
}
.react-calendar__tile--now{
  background: white !important;
  color: #002985 !important;
  font-weight: bold;
}

.react-calendar__tile--active{
  background: #002985 !important;
  color: white !important;
  border-radius: 50px;
  font-weight: 400;
}

.react-calendar__viewContainer > div > div > div > div.react-calendar__month-view__days > button > abbr{
  font-size: 13px;
}
.rbc-header, button.react-calendar__navigation__label > span{
  font-size: 10px;
  font-weight: bold;
}

.react-calendar {
  width: 305px !important;
  background: #FFFFFF;
  box-shadow: 0px 3px 14px rgba(0, 0, 0, 0.03), 0px 3px 14px rgba(19, 10, 46, 0.03), 0px 1px 3px rgba(19, 10, 46, 0.13);
  border-radius: 12px !important;
  border: none !important
}


.text-item-table-privider{
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    color: #333333;
}

.icon-user {
    width: 26px;
    height: 26px;
    left: 421px;
    top: 336px;
    object-fit: none;
    /* justo blanco */

    background: #FFFFFF;
    border: 1px solid rgba(51, 51, 51, 0.1);
    border-radius: 50%;
    margin: 10px 10px 0 0;
}

.icon-user img {
    left: 8.6%;
    right: 8.48%;
    top: 8.6%;
    bottom: 8.31%;
}

.content-user {
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    display: flex;
    align-items: center;
    color: #333333;
}

.date-send {
    width: 176px;
    width: 176px;
    opacity: 1;
    font-family: Lato;
    font-style: italic;
    font-weight: normal;
    font-size: 12px;
    padding-top: 10px;
    color: #929494;
}

.container-message {
    border-top: 1px solid rgba(0, 0, 0, .1);
    padding: 5px;
    margin-top: 2px;
}
.text-details-incidence {
    font-family: Lato;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: left;
    padding: 0 0 0 37px;
    color: #333333;
}

.link-file {
    color: rgba(0,0,0,250,.5);
    font-weight:'500';

}

.text-details-incidence img {
    padding: 32px 0;
}

.content-bg, .content {
    width: 100%;
    height: 167px;
    left: 398px;
    top: 657px;
    padding: 16px 0 0 16px;
    background: #F2F5F5;
    border-top: 1px solid rgb(51 51 51 / 16%);
    border-bottom: 1px solid rgb(51 51 51 / 16%);

}

.content {
    background: #FFFFFF;
    border-top: none;
    border-bottom: none;
    height: 100%;
    min-height: 167px;
}

.container-btn-response {
    padding: 30px 0;
}
.text-new-incidence{
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;

    /* justo negro */
    color: #333333;
    padding-top: 37px;
}

.text-new-incidence-not-first{
    padding-bottom: 37px;
}

.button-load-file-form{
    margin-bottom: 0px;
    background: rgba(2, 70, 56, 0.05);
}

.text-end{
    text-align: center;
}

.label-form{
    height: 25px;
}

.text-area-form{
    height: 109px;
    padding: 10px;
}

#selectFileOC,
#selectFileEvidence{
    width: auto !important;
    display: none;
}

.button-save-incidence{
    width: 194px;
    height: 60px;

    /* justo verde */
    background: #2D4838;
    opacity: 0.2;
    border-radius: 5px;
}

.button-load-incidence {
    width: 100%;
}

.button-save-active{
    opacity: 1;
}
.title-table-orders{
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 12px;

    /* identical to box height */
    text-transform: uppercase;

    /* justo verde */
    color: #2D4838;
}

.icon-export-provider{
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    text-align: center;

    /* justo negro */
    color: #333333;
    float: left;
    padding-right: 5px;
    padding-left: 5px;
    margin-top: -8px;
}

.icon-export-provider a{
    color: #333333;
    font-size: 12px;
    line-height: 14px;
}

.icon-export-provider a:hover{
    text-decoration: none;
}


.icon-export-provider.empty{
    background: #e0e4e2;
}

.empty img{
    padding-top: 5px;
    padding-bottom: 3px;
}

.icon-action-provider{
    padding-right: 10px;
    padding-left: 10px;
    float: left;
}

.body-provider{
    margin-left: 80px;
    padding-bottom: 50px;
}

.status-orders{
    width: 123px !important;
    height: 29px !important;
}

.btnAddProduct{
    margin-top: 29px;
    width: 165px;
    height: 50px;

    border: 1px solid rgba(45, 72, 56, 0.5);
    box-sizing: border-box;
    border-radius: 5px;

    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 22px;
    color: #333333;
    background: white;
}

.total-orders{
    padding: 10px;
    width: 100%;
    text-align: right;
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: 10px;
    line-height: 12px;

    /* identical to box height */
    text-transform: uppercase;

    /* justo verde */
    color: #2D4838;
}

.total-orders span{
    padding-left: 30px;
    padding-right: 10px;
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 22px;

    /* identical to box height */

    /* justo negro */
    color: #333333;
}

.created_at{
    padding-left: 15px;
}

.icon-remove{
    margin-top: -1px;
}

.item-actions{
    float: right;
}

.text-center{
    text-align: center;
}

.container-select-day {
    margin: 0 auto;
    width: 98%;
}

.item-privider {
    font-family: Lato;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    color: #333333;
    padding-bottom: 30px;
}

.label-order {
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;
    color: #333333;
    padding-bottom: 10px;

}

.container-modal-btn-schedule {
    width: 100%;
    margin-top: 40px;
}
.header-provider{
    margin-left: 80px;
    height: 222px;
    background: #F2F5F5;
    padding-top: 40px;
}

.header-provider-detail{
    margin-left: 80px;
    height: 169px;
    background: #F2F5F5;
}

.header-provider-detail .title{
    font-family: Lato;
    font-style: normal;
    font-weight: 800;
    font-size: 40px;
    line-height: 48px;

    /* identical to box height */

    /* justo verde */
    color: #2D4838;
    margin-left: 80px;
    padding-top: 28px;
}


.title-view-header-provider{
    font-family: Lato;
    font-style: normal;
    font-weight: 800;
    font-size: 40px;
    line-height: 48px;

    /* identical to box height */

    /* justo verde */
    color: #2D4838;
}

.title-view-header-provider-detail{
    padding-left: 20px;
    padding-top: 27px;
}
.subtitle-view-header-provider-detail{
    padding-left: 20px;
    color:rgba(51, 51, 51,.7)
}

.input-header-search-provider{
    height: 45px;
    padding-left: 20px;
    padding-right: 20px;

    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 22px;

    /* identical to box height */

    /* justo negro */
    color: #333333;
    width: 80%;
    opacity: 0.3;
}


.con-provider {
    background: #FFFFFF;
    border: 1px solid rgba(45, 72, 56, 0.2);
    box-sizing: border-box;
    border-radius: 5px;
    height: 50px;

    position:relative;
    margin-top: 29px;
}

.con-provider input {
    margin:0 0 0 4px;
    border:0;
}

.con-provider .MuiSvgIcon-root {
    display:block;
    position:absolute;
    top: 10px;
    right: 20px;
    cursor:pointer;
    font-size: 2rem;
    color: gray;
}

.button-header-provider{
    margin-left: 10px;
    margin-top: 29px;
    height: 50px;

    border: 1px solid rgba(45, 72, 56, 0.5);
    box-sizing: border-box;
    border-radius: 5px;
    width: 110px;
    padding: 12px 15px;
}

.button-header-provider span{
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 22px;

    /* identical to box height */

    /* justo negro */
    color: #333333;

    opacity: 0.5;
    padding-right: 12px;
}

.button-header-new, .btnDetails{
    margin-top: 29px;
    width: 235px;
    height: 50px;

    /* justo verde */
    background: #2D4838;
    border-radius: 5px;
}

.btnDetails {
    width: 295px;
}

.button-header-new, .btnDetails .text{
    font-family: Lato;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;

    /* identical to box height */

    /* justo blanco */
    color: #FFFFFF;
}

.title-modal-provider{
    font-family: Lato;
    font-style: normal;
    font-weight: 800;
    font-size: 25px;
    line-height: 30px;
    line-height: 19px;
    text-align: center;

    /* justo negro */
    color: #2D4838;
    background-color: #F2F5F5;
    padding: 25px 20px;
    border-radius: 10px;
    margin-bottom: 47px;
}

.title-modal-provider img {
    float: right;
}

.documents-modal-provider {
    display: flex;
    width: 100%;
    justify-content: center;
}

.button-save-modal{
    width: 194px;
    height: 60px;

    /* justo verde */
    background: #2D4838;
    opacity: 0.2;
    border-radius: 5px;
}

.button-save-modal .text, .button-save-modal-active, .button-save-incidence .text{
    font-family: Lato;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;

    /* identical to box height */
    text-align: center;

    /* justo blanco */
    color: #FFFFFF;
}

.container-btn-save {
    margin: 0 auto;
    width: 195px;
}

.button-load-products-modal, .button-load-products-file-modal {
    width: 490px;
}

.rigth{
    float: right;
    margin-left: 20px;
    min-width: 135px;
    cursor: pointer;
}

.button-load-incidence{
    width: 100%;
}

.rigth img {
    opacity: 50%;
}

.button-load-products-file, .button-load-products-file-modal .text-load{
    background: rgba(2, 70, 56, 0.05);
    width: 100%;
}

@media only screen and (max-width: 470px) {
    .input-header-search-provider{
        padding-left: 10px;
        padding-right: 0;
    }

    .button-header-provider span{
        font-size: 14px;
    }

    .button-header-new{
        margin-top: 10px;
        width: 206px;
    }

    .button-header-new .text{
        font-size: 14px;
    }
    .con-provider {
        margin-top: 0;
    }

    .header-provider{
        height: 100%;
        padding: 20px 0;

    }

}
.text-item-table-privider{
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    color: #333333;
}

.icon-user {
    width: 26px;
    height: 26px;
    left: 421px;
    top: 336px;
    object-fit: none;
    /* justo blanco */

    background: #FFFFFF;
    border: 1px solid rgba(51, 51, 51, 0.1);
    border-radius: 50%;
    margin: 10px 10px 0 0;
}

.icon-user img {
    left: 8.6%;
    right: 8.48%;
    top: 8.6%;
    bottom: 8.31%;
}

.content-user {
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    display: flex;
    align-items: center;
    color: #333333;
}

.date-send {
    width: 176px;
    width: 176px;
    opacity: 1;
    font-family: Lato;
    font-style: italic;
    font-weight: normal;
    font-size: 12px;
    padding-top: 10px;
    color: #929494;
}

.container-message {
    border-top: 1px solid rgba(0, 0, 0, .1);
    padding: 5px;
    margin-top: 2px;
}
.text-details-incidence {
    font-family: Lato;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: left;
    padding: 0 0 0 37px;
    color: #333333;
}

.link-file {
    color: rgba(0,0,0,250,.5);
    font-weight:'500';

}

.text-details-incidence img {
    padding: 32px 0;
}

.content-bg, .content {
    width: 100%;
    height: 167px;
    left: 398px;
    top: 657px;
    padding: 16px 0 0 16px;
    background: #F2F5F5;
    border-top: 1px solid rgb(51 51 51 / 16%);
    border-bottom: 1px solid rgb(51 51 51 / 16%);

}

.content {
    background: #FFFFFF;
    border-top: none;
    border-bottom: none;
    height: 100%;
    min-height: 167px;
}

.container-btn-response {
    padding: 30px 0;
}
.text-new-incidence{
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;

    /* justo negro */
    color: #333333;
    padding-top: 37px;
}

.text-new-incidence-not-first{
    padding-bottom: 37px;
}

.button-load-file-form{
    margin-bottom: 0px;
    background: rgba(2, 70, 56, 0.05);
}

.text-end{
    text-align: center;
}

.label-form{
    height: 25px;
}

.text-area-form{
    height: 109px;
    padding: 10px;
}

#selectFileOC,
#selectFileEvidence{
    width: auto !important;
    display: none;
}

.button-save-incidence{
    width: 194px;
    height: 60px;

    /* justo verde */
    background: #2D4838;
    opacity: 0.2;
    border-radius: 5px;
}

.button-load-incidence {
    width: 100%;
}

.button-save-active{
    opacity: 1;
}
.header-provider{
    margin-left: 80px;
    height: 222px;  
    background: #F2F5F5;
    padding-top: 40px;
}

.header-provider-detail{
    margin-left: 80px;
    height: 169px;
    background: #F2F5F5;
}

.header-provider-detail .title{
    font-family: Lato;
    font-style: normal;
    font-weight: 800;
    font-size: 40px;
    line-height: 48px;

    /* identical to box height */

    /* justo verde */
    color: #2D4838;
    margin-left: 80px;
    padding-top: 28px;
}


.title-view-header-provider{
    font-family: Lato;
    font-style: normal;
    font-weight: 800;
    font-size: 40px;
    line-height: 48px;

    /* identical to box height */

    /* justo verde */
    color: #2D4838;
}

.input-header-search-provider{
    height: 45px;
    padding-left: 20px;
    padding-right: 20px;

    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 22px;

    /* identical to box height */

    /* justo negro */
    color: #333333;
    width: 80%;
    opacity: 0.3;
}


.con-provider { 
    background: #FFFFFF;
    border: 1px solid rgba(45, 72, 56, 0.2);
    box-sizing: border-box;
    border-radius: 5px;
    height: 50px;
    
    position:relative; 
    margin-top: 29px;
}

.con-provider input { 
    margin:0 0 0 4px; 
    border:0; 
}

.con-provider .MuiSvgIcon-root { 
    display:block; 
    position:absolute; 
    top: 10px; 
    right: 20px; 
    cursor:pointer; 
    font-size: 2rem;
    color: gray;
}

.button-header-provider{
    margin-left: 10px;
    margin-top: 29px;
    height: 50px;

    border: 1px solid rgba(45, 72, 56, 0.5);
    box-sizing: border-box;
    border-radius: 5px;
    width: 110px;
    padding: 12px 15px;
}

.button-header-provider span{
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 22px;

    /* identical to box height */

    /* justo negro */
    color: #333333;

    opacity: 0.5;
    padding-right: 12px;
}

.title-header-admin{
    font-family: Lato;
    font-style: normal;
    font-weight: 800;
    font-size: 32px;
    line-height: 48px;
    color: #2D4838;
    margin-left: 30px;
    padding-top: 28px;
}

.button-header-new, .btnDetails{
    margin-top: 29px;
    width: 235px;
    height: 50px;

    /* justo verde */
    background: #2D4838;
    border-radius: 5px;
}

.button-header-new.disable{
    opacity: 0.2;
}

.btnDetails {
    width: 295px;
}

.button-header-new, .btnDetails .text{
    font-family: Lato;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;

    /* identical to box height */

    /* justo blanco */
    color: #FFFFFF;
}

.title-modal-provider{
    font-family: Lato;
    font-style: normal;
    font-weight: 800;
    font-size: 25px;
    line-height: 30px;
    line-height: 19px;
    text-align: center;

    /* justo negro */
    color: #2D4838;
    background-color: #F2F5F5;
    padding: 25px 20px;
    border-radius: 10px;
    margin-bottom: 47px;
}

.title-modal-provider img {
    float: right;
}

.documents-modal-provider {
    display: flex;
    width: 100%;
    justify-content: center;
}

.button-save-modal{
    width: 194px;
    height: 60px;

    /* justo verde */
    background: #2D4838;
    opacity: 0.2;
    border-radius: 5px;
}

.button-save-modal .text, .button-save-modal-active .text{
    font-family: Lato;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;

    /* identical to box height */
    text-align: center;

    /* justo blanco */
    color: #FFFFFF;
}

.container-btn-save {
    margin: 0 auto;
    width: 195px;
}

.button-load-products-modal, .button-load-products-file-modal {
    width: 490px;
}

.rigth{
    float: right;
    margin-left: 20px;
    min-width: 135px;
    cursor: pointer;
}

.button-load-incidence{
    width: 100%;
}

.rigth img {
    opacity: 50%;
}

.button-load-products-file, .button-load-products-file-modal .text-load{
    background: rgba(2, 70, 56, 0.05);
    width: 460px;
}

.select-providers-header{
    width: 300px;
}

@media only screen and (max-width: 470px) {
    .input-header-search-provider{
        padding-left: 10px;
        padding-right: 0;
    }
    
    .button-header-provider span{
        font-size: 14px;
    }
    
    .button-header-new{
        margin-top: 10px;
        width: 206px;
    }
    
    .button-header-new .text{
        font-size: 14px;
    }
    .con-provider {
        margin-top: 0;
    }

    .header-provider{
        height: 100%;
        padding: 20px 0;

    }
    
}
.icon-user {
    width: 26px;
    height: 26px;
    left: 421px;
    top: 336px;
    object-fit: none;
    /* justo blanco */

    background: #FFFFFF;
    border: 1px solid rgba(51, 51, 51, 0.1);
    border-radius: 50%;
    margin: 10px 10px 0 0;
}

.icon-user img {
    left: 8.6%;
    right: 8.48%;
    top: 8.6%;
    bottom: 8.31%;
}

.content-user {
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    display: flex;
    align-items: center;
}

.date-send {
    width: 176px;
    width: 176px;
    opacity: 1;
    font-family: Lato;
    font-style: italic;
    font-weight: normal;
    font-size: 12px;
    padding-top: 10px;
    color: #929494;
}

.text-details-incidence {
    font-family: Lato;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: left;
    padding: 0 0 0 37px;

}

.text-details-incidence img {
    padding: 32px 0;

}

.content-bg, .content {
    width: 100%;
    height: 167px;
    left: 398px;
    top: 657px;
    padding: 16px 0 0 16px;
    background: #F2F5F5;
    border-top: 1px solid rgb(51 51 51 / 16%);
    border-bottom: 1px solid rgb(51 51 51 / 16%);

}

.content {
    background: #FFFFFF;
    border-top: none;
    border-bottom: none;
    height: 100%;
    min-height: 167px;
}

.container-btn-response {
    padding: 30px 0;
}

.text-item-table-privider{
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    color: #333333;
}
.header-provider{
    margin-left: 80px;
    height: 222px;  
    background: #F2F5F5;
    padding-top: 40px;
}

.header-provider-detail{
    margin-left: 80px;
    padding-bottom: 50px;
    height: 100%;
    background: #F2F5F5;
}

.header-provider-detail .title{
    font-family: Lato;
    font-style: normal;
    font-weight: 800;
    font-size: 40px;
    line-height: 48px;

    /* identical to box height */

    /* justo verde */
    color: #2D4838;
    margin-left: 80px;
    padding-top: 28px;
}


.title-view-header-provider{
    font-family: Lato;
    font-style: normal;
    font-weight: 800;
    font-size: 40px;
    line-height: 48px;

    /* identical to box height */

    /* justo verde */
    color: #2D4838;
}

.input-header-search-provider{
    height: 45px;
    padding-left: 20px;
    padding-right: 20px;

    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 22px;

    /* identical to box height */

    /* justo negro */
    color: #333333;
    width: 80%;
    opacity: 0.3;
}


.con-provider { 
    background: #FFFFFF;
    border: 1px solid rgba(45, 72, 56, 0.2);
    box-sizing: border-box;
    border-radius: 5px;
    height: 50px;
    
    position:relative; 
    margin-top: 29px;
}

.con-provider input { 
    margin:0 0 0 4px; 
    border:0; 
}

.con-provider .MuiSvgIcon-root { 
    display:block; 
    position:absolute; 
    top: 10px; 
    right: 20px; 
    cursor:pointer; 
    font-size: 2rem;
    color: gray;
}

.button-header-provider{
    margin-left: 10px;
    margin-top: 29px;
    height: 50px;

    border: 1px solid rgba(45, 72, 56, 0.5);
    box-sizing: border-box;
    border-radius: 5px;
    width: 110px;
    padding: 12px 15px;
}

.button-header-provider span{
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 22px;

    /* identical to box height */

    /* justo negro */
    color: #333333;

    opacity: 0.5;
    padding-right: 12px;
}

.title-header-admin{
    font-family: Lato;
    font-style: normal;
    font-weight: 800;
    font-size: 32px;
    line-height: 48px;
    color: #2D4838;
    margin-left: 30px;
    padding-top: 28px;
}

.button-header-new, .btnDetails{
    margin-top: 29px;
    width: 235px;
    height: 50px;

    /* justo verde */
    background: #2D4838;
    border-radius: 5px;
}

.button-header-new.disable{
    opacity: 0.2;
}

.btnDetails {
    width: 295px;
}

.button-header-new, .btnDetails .text{
    font-family: Lato;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;

    /* identical to box height */

    /* justo blanco */
    color: #FFFFFF;
}

.title-modal-provider{
    font-family: Lato;
    font-style: normal;
    font-weight: 800;
    font-size: 25px;
    line-height: 30px;
    line-height: 19px;
    text-align: center;

    /* justo negro */
    color: #2D4838;
    background-color: #F2F5F5;
    padding: 25px 20px;
    border-radius: 10px;
    margin-bottom: 47px;
}

.title-modal-provider img {
    float: right;
}

.documents-modal-provider {
    display: flex;
    width: 100%;
    justify-content: center;
}

.button-save-modal{
    width: 194px;
    height: 60px;

    /* justo verde */
    background: #2D4838;
    opacity: 0.2;
    border-radius: 5px;
}

.button-save-modal .text, .button-save-modal-active .text{
    font-family: Lato;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;

    /* identical to box height */
    text-align: center;

    /* justo blanco */
    color: #FFFFFF;
}

.container-btn-save {
    margin: 0 auto;
    width: 195px;
}

.button-load-products-modal, .button-load-products-file-modal {
    width: 490px;
}

.rigth{
    float: right;
    margin-left: 20px;
    min-width: 135px;
    cursor: pointer;
}

.button-load-incidence{
    width: 100%;
}

.rigth img {
    opacity: 50%;
}

.button-load-products-file, .button-load-products-file-modal .text-load{
    background: rgba(2, 70, 56, 0.05);
    width: 460px;
}

.select-providers-header{
    width: 300px;
}

@media only screen and (max-width: 470px) {
    .input-header-search-provider{
        padding-left: 10px;
        padding-right: 0;
    }
    
    .button-header-provider span{
        font-size: 14px;
    }
    
    .button-header-new{
        margin-top: 10px;
        width: 206px;
    }
    
    .button-header-new .text{
        font-size: 14px;
    }
    .con-provider {
        margin-top: 0;
    }

    .header-provider{
        height: 100%;
        padding: 20px 0;

    }
    
}

