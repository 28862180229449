.NotFound {
    padding-top: 100px;
}

.title-404{
  font-size: 100px;
  color: #2D4838;
}


.title-page{
  font-size: 40px;
  color: #2D4838;
  padding-bottom: 50px;
}