.title-table{
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    text-transform: uppercase;

    /* justo verde */
    color: #2D4838;
}

.desk {
    display: block

}

.mobile {
    display: none;
}

.text-item-table{
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 24px;

    /* identical to box height */

    /* justo negro */
    color: #333333;
}

.row-title{
    border-bottom: 1px solid #c6c4c4;
}

.row-body{
    border-bottom: 1px solid #e5e3e3;
}

.row-table{
    padding-top: 20px;
    padding-bottom: 20px;
}

.not-first{
    text-align: center;
}

.menu-status{
    text-transform: none !important;
}

.item-status-provider{
    margin-top: -5px;
    font-size: 14px;
}

.menu-status-w {
    width: 10px;
}

.status{
    width: 135px;
    height: 41px;
    border-radius: 40px;
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #333333;
    
    margin-top: -6px;
}

.status.pending{
    background: #ffedcb;
    color: red;
}

.status.active{
    background: #d5f5d5;
}

.status.inactive{
    background: #f6f7f6;
}

.product{
    display: block;
}

.product, .date {
    display: flex;
}

.department{
    text-align: left;
    border-bottom: 0.5px solid #eae7e7;
}

.icon-more{
    color: #939797;
    cursor: pointer;
}

.icons-header-table{
    width: 100%;
}

.icons-header-table .icon-export{
    float: right;
    width: 3rem;
    cursor: pointer;
}

.MuiGrid-grid-xs-3 {
    flex-grow: 0;
    max-width: 25%;
    flex-basis: 25%;
    display: flex;
}

.providers {
    margin-bottom: 20rem;
}

.providers .paginator{
    padding-top: 30px;
}

.providers .paginator nav{
    float: right;
}

.container-details {
    display: flex;
    justify-content: space-between;
}

.content-card {
    font-family: Lato;
    font-style: normal;
    font-weight: 800;
    font-size: 25px;
    line-height: 30px;
    text-align: center;
    height: 234px;
    align-items: center;
    display: flex;
    justify-content: center;
}

@media only screen and (max-width: 470px) {
    .product, .date {
        display: none;
    }
    .MuiGrid-grid-xs-3 {
        max-width: 25%;
        flex-basis: 0;
    }
    .MuiGrid-grid-xs-3 {
        display: none;
    }
    .providers {
        margin-bottom: 91px;
    }
}

@media only screen and (max-width: 768px) {

    .status{
        font-size: 15px;
        width: 75px;

    }
    .desk {
        display: none
    
    }
    
    .mobile {
        display: flex;

    }
    
}
