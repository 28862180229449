.text-new-incidence{
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;

    /* justo negro */
    color: #333333;
    padding-top: 37px;
}

.text-new-incidence-not-first{
    padding-bottom: 37px;
}

.button-load-file-form{
    margin-bottom: 0px;
    background: rgba(2, 70, 56, 0.05);
}

.text-end{
    text-align: center;
}

.label-form{
    height: 25px;
}

.text-area-form{
    height: 109px;
    padding: 10px;
}

#selectFileOC,
#selectFileEvidence{
    width: auto !important;
    display: none;
}

.button-save-incidence{
    width: 194px;
    height: 60px;

    /* justo verde */
    background: #2D4838;
    opacity: 0.2;
    border-radius: 5px;
}

.button-load-incidence {
    width: 100%;
}

.button-save-active{
    opacity: 1;
}