.header-provider{
    margin-left: 80px;
    height: 222px;
    background: #F2F5F5;
    padding-top: 40px;
}

.header-provider-detail{
    margin-left: 80px;
    height: 169px;
    background: #F2F5F5;
}

.header-provider-detail .title{
    font-family: Lato;
    font-style: normal;
    font-weight: 800;
    font-size: 40px;
    line-height: 48px;

    /* identical to box height */

    /* justo verde */
    color: #2D4838;
    margin-left: 80px;
    padding-top: 28px;
}


.title-view-header-provider{
    font-family: Lato;
    font-style: normal;
    font-weight: 800;
    font-size: 40px;
    line-height: 48px;

    /* identical to box height */

    /* justo verde */
    color: #2D4838;
}

.title-view-header-provider-detail{
    padding-left: 80px;
    padding-top: 27px;
}
.subtitle-view-header-provider-detail{
    padding-left: 80px;
    color:rgba(51, 51, 51,.7)
}

.input-header-search-provider{
    height: 45px;
    padding-left: 20px;
    padding-right: 20px;

    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 22px;

    /* identical to box height */

    /* justo negro */
    color: #333333;
    width: 80%;
    opacity: 0.3;
}


.con-provider {
    background: #FFFFFF;
    border: 1px solid rgba(45, 72, 56, 0.2);
    box-sizing: border-box;
    border-radius: 5px;
    height: 50px;

    position:relative;
    margin-top: 29px;
}

.con-provider input {
    margin:0 0 0 4px;
    border:0;
}

.con-provider .MuiSvgIcon-root {
    display:block;
    position:absolute;
    top: 10px;
    right: 20px;
    cursor:pointer;
    font-size: 2rem;
    color: gray;
}

.button-header-provider{
    margin-left: 10px;
    margin-top: 29px;
    height: 50px;

    border: 1px solid rgba(45, 72, 56, 0.5);
    box-sizing: border-box;
    border-radius: 5px;
    width: 110px;
    padding: 12px 15px;
}

.button-header-provider span{
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 22px;

    /* identical to box height */

    /* justo negro */
    color: #333333;

    opacity: 0.5;
    padding-right: 12px;
}

.button-header-new, .btnDetails{
    margin-top: 29px;
    width: 235px;
    height: 50px;

    /* justo verde */
    background: #2D4838;
    border-radius: 5px;
}

.btnDetails {
    width: 295px;
}

.button-header-new, .btnDetails .text{
    font-family: Lato;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;

    /* identical to box height */

    /* justo blanco */
    color: #FFFFFF;
}

.title-modal-provider{
    font-family: Lato;
    font-style: normal;
    font-weight: 800;
    font-size: 25px;
    line-height: 30px;
    line-height: 19px;
    text-align: center;

    /* justo negro */
    color: #2D4838;
    background-color: #F2F5F5;
    padding: 25px 20px;
    border-radius: 10px;
    margin-bottom: 47px;
}

.title-modal-provider img {
    float: right;
}

.documents-modal-provider {
    display: flex;
    width: 100%;
    justify-content: center;
}

.button-save-modal{
    width: 194px;
    height: 60px;

    /* justo verde */
    background: #2D4838;
    opacity: 0.2;
    border-radius: 5px;
}

.button-save-modal .text, .button-save-modal-active, .button-save-incidence .text{
    font-family: Lato;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;

    /* identical to box height */
    text-align: center;

    /* justo blanco */
    color: #FFFFFF;
}

.container-btn-save {
    margin: 0 auto;
    width: 195px;
}

.button-load-products-modal, .button-load-products-file-modal {
    width: 490px;
}

.rigth{
    float: right;
    margin-left: 20px;
    min-width: 135px;
    cursor: pointer;
}

.button-load-incidence{
    width: 100%;
}

.rigth img {
    opacity: 50%;
}

.button-load-products-file, .button-load-products-file-modal .text-load{
    background: rgba(2, 70, 56, 0.05);
    width: 100%;
}

@media only screen and (max-width: 470px) {
    .input-header-search-provider{
        padding-left: 10px;
        padding-right: 0;
    }

    .button-header-provider span{
        font-size: 14px;
    }

    .button-header-new{
        margin-top: 10px;
        width: 206px;
    }

    .button-header-new .text{
        font-size: 14px;
    }
    .con-provider {
        margin-top: 0;
    }

    .header-provider{
        height: 100%;
        padding: 20px 0;

    }

}