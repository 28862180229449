.footer{
    height: 61px;
    background: #2D4838;
    text-align: center;
}

.footer ul {
    display: inline-flex;
    justify-content: space-evenly;
    align-items: center;
    height: 100%;
    width: 50%;
    margin: 0;
    padding: 0;
    zoom: 1;
    /* For IE, the outcast */
    zoom:1;
    *display: inline;
}

.footer li {
    opacity: 0.5;
}


.footer ul li {
    float: left;
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #FFFFFF;
}

@media only screen and (max-width: 470px) {
    .footer{
        height: 86px;
    }

    .footer ul li {
        text-align: center;
        float: none;
        font-size: 14px;
        display: block;
    }
    .footer ul {
    padding: 13px 0 0 0;
    display: block;
    justify-content: space-evenly;
    width: 100%;
    display: block;
    }
    
}

@media only screen and (max-width: 768px) {

    .footer ul {
    width: 100%;
    padding: 13px 0 0 0;
    }
    
}
