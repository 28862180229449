.title-table-orders{
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 12px;

    /* identical to box height */
    text-transform: uppercase;

    /* justo verde */
    color: #2D4838;
}

.sort{
    cursor: pointer;
}

.item-provider{
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;

    /* identical to box height */

    /* justo negro */
    color: #333333;
    text-align: left;
}

.item-provider span{
    font-size: 14px !important;
}

.item-status{
    text-align: center;
    padding-top: 4px;
}

.icon-export-provider{
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    text-align: center;

    /* justo negro */
    color: #333333;
    float: left;
    padding-right: 10px;
}

.status-provider{
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: Lato sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    text-align: center;

    /* justo negro */
    color: #333333;

    opacity: 0.7;
    width: 82px;
    height: 29px;

    background: #D6F5D5;
    border-radius: 40px;
    margin-top: -6px;
}

.status-provider.pending{
    background: #ffedcb;
    color: red;
}

.status-provider.active{
    background: #d5f5d5;
}

.status-provider.inactive{
    background: #f6f7f6;
}

.MuiPaginationItem-page.Mui-selected, .MuiPaginationItem-page.Mui-selected:hover{
    background: #2D4838 !important;
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;

    color: #FFFFFF !important;
}

.MuiPaginationItem-page, .MuiPaginationItem-page:hover{
    background: #e0e4e2 !important;
    font-family: Lato !important;
    font-size: 12px !important;
    line-height: 14px !important;

    /* justo verde */
    color: #2D4838 !important;
}

.mobilNone {
    display: block;
}

.tooltip{
    cursor: pointer;
}

.modal-delete{
    width: 200;
    background-color: white;
    border: 1px solid #fff;
    border-radius: 10px;
    /* box-shadow: , */
    padding: 20px;
    outline: 0;
}

.modal-import{
    width: 584px;
    background-color: white;
    border: 1px solid #fff;
    border-radius: 10px;
    /* box-shadow: , */
    padding: 20px;
    outline: 0;
}

.modal-content {
    display: flex;
    align-items: center;
    justify-content: center;
}

.button-delete{
    cursor: pointer;
    width: 194px;
    height: 50px;

    /* justo verde */
    background: #2D4838;
    border-radius: 5px;

    font-family: Lato;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;

    /* identical to box height */

    /* justo blanco */
    color: #FFFFFF;
}

.container-modal-detele{
    padding-bottom: 10px;
}

.button-cancel{
    cursor: pointer;
    width: 194px;
    height: 50px;

    /* justo verde */
    border: 1px solid #2D4838;
    box-sizing: border-box;
    border-radius: 5px;

    font-family: Lato;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;

    /* identical to box height */

    /* justo verde */
    color: #2D4838;
}

.button-load-file{
    font-family: Lato;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    padding-left: 10px;
    color: #333333;
    background: rgba(2, 70, 56, 0.05);
    width: 100%;
    padding-top: 17px;
    height: 60px;
    background: rgba(2, 70, 56, 0.05);
    border: 1px solid rgba(45, 72, 56, 0.2);
    box-sizing: border-box;
    border-radius: 5px;
    margin-bottom: 50px;
}

.img-button-load{
    border: none;
}

.button-modal-save{
    width: 194px;
    height: 50px;

    /* justo verde */
    background: #2D4838;
    opacity: 0.2;
    border-radius: 5px;

    font-family: Lato;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;

    /* identical to box height */

    /* justo blanco */
    color: #FFFFFF;
}

.button-modal-save-active{
    opacity: 1 !important;
}

.text-load-modal, .img-button-modal {
    display: flex;
    align-items: center;
    padding: 0;
}

.antes-nota{
    margin-bottom: 10px;
}

.nota{
    font-size: 10px;
    color: #333333;
    font-style: italic;
}

.nota strong{
    font-style: normal;
    font-weight: bold;
}

@media only screen and (max-width: 768px) {
    .mobilNone {
        display: none;
    }
    .providers {
        margin-bottom: 91px;
    }
}

