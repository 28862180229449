.iframe-reports{
    border: none;
    width: 100%;
    padding-bottom: 30px;
    height: 760px;
}

.error-reports{
    border: none;
    width: 100%;
    padding-bottom: 30px;
    padding-top: 100px;
    height: 760px;
    text-align: center;
}

.text-error{
    font-family: Lato;
    font-style: normal;
    font-weight: 800;
    font-size: 40px;
    line-height: 48px;
    color: #2D4838;
}