.container-load-products{
    padding-bottom: 40px;
}

.title-section{
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;

    /* identical to box height */
    text-transform: uppercase;

    /* justo negro */
    color: #333333;

    opacity: 0.7;
    margin-bottom: 50px;
}

.label{
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;

    /* justo negro */
    color: #333333;
    padding-bottom: 30px;
}

.button-load-products, .button-load-products-modal{
    width: 460px;
    height: 60px;

    /* justo blanco */
    background: #FFFFFF;

    /* justo verde */
    border: 1px solid #2D4838;
    box-sizing: border-box;
    border-radius: 5px;
    margin-bottom: 50px;
}

.button-load-products, .button-load-products-modal .img-button{
    text-align: center;
    padding-top: 17px;
}

.button-load-products, .button-load-products-modal .text-button{
    padding-top: 15px;
    font-family: Lato;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;

    /* identical to box height */
    text-align: center;

    color: #2D4838;
}

.button-load-products-file, .button-load-products-file-modal{
    width: 460px;
    height: 60px;

    background: rgba(2, 70, 56, 0.05);
    border: 1px solid rgba(45, 72, 56, 0.2);
    box-sizing: border-box;
    border-radius: 5px;
    margin-bottom: 50px;
}

.button-load-products-file, .button-load-products-file-modal .text-button-not-loaded{
    padding-top: px !important;
    text-align: center;
}

.button-load-products-file, .button-load-products-file-modal .img-button{
    background: transparent;
}

.button-load-products-file, .button-load-products-file-modal .text-button{
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: 13px !important;
    line-height: 17px;
    text-align: left;
    
    /* identical to box height */
    
    /* justo negro */
    color: #333333;
    
    opacity: 0.7;
}

.button-load-products-file, .text-load-modal, .button-load-products-file-modal .text-load{
    padding-left: 10px;
    font-family: Lato;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;

    /* identical to box height */

    /* justo negro */
    color: #333333;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.text-load-product, .img-button-product {
    display: flex;
    align-items: center;
    margin-top: 16px;
}

.text-underline{
    border-bottom: 2px solid #333333;
}

.text-underline-load-products{
    font-size: 13px;
    border-bottom: 1px solid #333333;
}

.button-save{
    width: 338px;
    height: 60px;

    /* justo verde */
    background: #2D4838;
    opacity: 0.2;
    border-radius: 5px;
}

.button-save-active{
    width: 338px;
    height: 60px;

    /* justo verde */
    background: #2D4838;
    opacity: 1;
    border-radius: 5px;
}


.button-save .text, .button-save-active .text{
    font-family: Lato;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;

    /* identical to box height */
    text-align: center;

    /* justo blanco */
    color: #FFFFFF;
}

#selectedFile{
    width: auto !important;
    display: none;
}


@media only screen and (max-width: 470px) {
    .button-load{
        width: 100%
    }
    .button-load-products,
    .button-load-products-file {
        width: 100%;
    }
    .button-load-products .text-button {
        font-size: 1rem;
      
    }
    
}
