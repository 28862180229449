.department{
    display: block;
}

.product{
    display: block;
    text-align: left;
}

.MuiTabs-centered {
    justify-content: center;
    background: #f2f5f5 !important;
}

.MuiTab-textColorPrimary.Mui-selected {
    color: #2d4838 !important;
}

.PrivateTabIndicator-colorPrimary-2 {
    background-color: #2d4838 !important;
}

.PrivateTabIndicator-colorPrimary-4 {
    background-color: #2d4838 !important;
}

.bgtabs {
    background-color: #F2F5F5;
}

.MuiTab-wrapper {
    font-weight: 700;
    text-transform: capitalize;
}

.button-Close-ticket {

width: 174px;
height: 50px;
left: 592px;
top: 1223px;

/* justo verde */

border: 1px solid #2D4838;
box-sizing: border-box;
border-radius: 5px;
margin-left: 20px;
}