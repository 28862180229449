@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");


:root {
    --primary: #2D4838;
    --main-bg: #ffffff;
    --second-bg: #FFC102;
    --txt-color: #455560;
    --txt-white: #fff;
    --main-color: #2D4838;
    --second-color: #F2F5F5;
    --box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;

    --main-bg-light: #ffffff;
    --second-bg-light: #fafafb;
    --txt-color-light: #455560;
    --box-shadow-light: rgba(149, 157, 165, 0.2) 0px 8px 24px;

    --main-bg-dark: #2d2d2d;
    --second-bg-dark: #202020;
    --txt-color-dark: #bbbbbb;
    --box-shadow-dark: rgba(0, 0, 0, 0.2) 0px 5px 10px;

    --main-color-blue: #349eff;
    --second-color-blue: #62b4ff;


    --sidebar-width: 100px;
    --border-radius: 5px;
    --topnav-height: 110px;
    --transition-cubic: cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;

    font-family: 'Lato', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    /* letter-spacing: 0.7px; */
    font-size: 16px;
    border: none;

}

a {
    text-decoration: none;
    color: unset;
}

a:hover {
    color: var(--main-color);
}

/* ul {
    list-style-type: none;
} */

input {
    border: 2px solid transparent;
    outline: 0;
}

/* input:focus {
    border: 2px solid var(--main-color);
} */

.page-header {
    margin-bottom: 40px;
    text-transform: capitalize;
}

.card {
    padding: 30px;
    margin-bottom: 30px;
    background-color: var(--main-bg);
    box-shadow: var(--box-shadow);
    border-radius: var(--border-radius);
}

.full-height {
    height: 100%;
}

.card.full-height {
    height: calc(100% - 30px);
}

.card__header {
    text-transform: capitalize;
}

.card > div ~ div {
    margin-top: 30px;
}

.card__footer {
    text-align: center;
    text-transform: capitalize;
}

.rdt_TableCell div{
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    color: #333333;
    text-align: left;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: unset;
}

.rdt_TableCell div.status-provider{
    text-align: center ;
}

.rdt_Table, .rdt_TableRow, .rdt_TableHeadRow, .rdt_TableHead, .rdt_TableBody{
    background: transparent !important;
}

.fSKJEl:hover, .cZbgXA:hover {
    background-color: #fffefe42 !important;
}

.rdt_TableHeadRow{
    font-family: Inter;
    font-style: normal;
    font-weight: bold !important;
    font-size: 10px !important;
    line-height: 12px !important;
    text-transform: uppercase;
    color: #2D4838;
}

.lnndaO{
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 12px;
    text-transform: uppercase;
    color: #2D4838;
}