.logo-admin{
    height: 70px;
    padding: 20px;
    padding-left: 0px;
}

.menu{
    height: 70px;
    padding: 25px;
    padding-right: 0px;
    float: right;

    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    text-align: center;

    /* justo negro */
    color: #333333;
    text-transform: none !important;

}

.arrow-logout{
    padding-left: 15px;
}

.box-title-admin{
    width: 100%;
    height: 272px;

    background: #F2F5F5;
    margin-bottom: 58px;
}

.box-title-admin-provider{
    width: 100%;
    height: 186px;

    background: #F2F5F5;
    margin-bottom: 58px;
}

.title-min{
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;

    /* identical to box height */

    /* justo verde */
    color: #2D4838;

    opacity: 0.5;
    padding: 24px 0 0 24px;
}

.title-view-admin{
    font-family: Lato;
    font-style: normal;
    font-weight: 800;
    font-size: 40px;

    /* identical to box height */

    /* justo verde */
    color: #2D4838;
    padding-top: 48px;
    padding-bottom: 40px;
}

.title-view-admin img {
    max-width: 30px;
}

.head-1{
    border-bottom: 1px solid #cde1d5;
}

.input-header-search{
    height: 55px;
    padding-left: 20px;
    padding-right: 20px;

    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 24px;

    /* identical to box height */

    /* justo negro */
    color: #333333;

    opacity: 0.3;
}

.con { 
    background: #FFFFFF;
    border: 1px solid rgba(45, 72, 56, 0.2);
    box-sizing: border-box;
    border-radius: 5px;
    height: 60px;
    
    width:100%; 
    position:relative; 
}

.con input { 
    margin:0 0 0 4px; 
    border:0; 
}

.con .MuiSvgIcon-root { 
    display:block; 
    position:absolute; 
    top: 15px; 
    right: 20px; 
    cursor:pointer; 
    font-size: 2rem;
    color: gray;
}

.MuiList-padding{
    width: 170px !important;
}

.MuiListItem-button{
    width: 170px !important;
}

@media only screen and (max-width: 725px) {
    .title-view-admin{
        padding-top: 22px;
        padding-bottom: 20px;
        font-size: 1.3rem; 
    }
    
    .head{
        border-bottom: 1px solid #cde1d5;
    }
    .box-title-admin {
        width: 100%;
        height: 202px;
        background: #F2F5F5;
        margin-bottom: 24px;
    }
    .title-view-admin img {
        max-width: 22px;
        font-size: 1.46rem;
    }
    
}