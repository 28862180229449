.container-sidebar{
    position: absolute;
    width: 80px;
    background: white;
    padding-left: 5px;
}

.list-sidebar{
    width: 70px;
    background: #F2F5F5;
    padding-left: 10px;
    padding-right: 10px;
    height: 932px;
}

.list-sidebar li{
    height: 75px;
    width: 70px;
    padding-top: 15px;
    text-align: center;
    list-style: none;
    margin-left: -10px;
    cursor: pointer;
}

.list-sidebar li span{
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    line-height: 12px;

    /* identical to box height */
    text-align: center;

    /* justo verde */
    color: #2D4838;

    opacity: 0.5;
    display: block;
}

.list-sidebar li span.active{
    font-weight: bold;
}

.active{
    background: white;
}