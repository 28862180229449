.head{
    border-bottom: 1px solid #cde1d5;
}

.logo-head{
    height: 70px;
    text-align: center;
    padding: 20px;
}

.box-title-breadcrumbs{
    width: 100%;
    height: 186px;

    background: #F2F5F5;
}

.box-title-breadcrumbs-home{
    width: 100%;
    height: 100%;

    background: #F2F5F5;
    margin-bottom: 50px;
}

.breadcrumbs{
    padding-top: 20px;
    padding-left: 20px;
    height: 50px;
}

.link-inactive{
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;

    /* identical to box height */

    /* justo verde */
    color: #2D4838;

    opacity: 0.5;
    padding-right: 10px;
}

.link-active{
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;

    /* identical to box height */

    /* justo verde */
    color: #2D4838;
}

.title-view{
    font-family: Lato;
    font-style: normal;
    font-weight: 800;
    font-size: 40px;
    line-height: 48px;
    margin: 43px 0;

    /* identical to box height */

    /* justo verde */
    color: #2D4838;
}

.image-home,
.image-home-hand{
    text-align: center;
}

.image-home-hand img {
    max-width: 320px;
    width: 100%;
}

.text-home{
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;

    /* justo verde */
    color: #2D4838;

    opacity: 0.7;
}

.text-home img{
    float: right;
    margin-top: 5px;
}

.text-underline-home{
    border-bottom: 2px solid #2D4838;
    cursor: pointer;
}

.icon-close{
    text-align: center;
    cursor: pointer;
}

.title-modal{
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    text-transform: uppercase;

    /* justo negro */
    color: #333333;

    opacity: 0.7;
    padding-top: 30px;
    padding-bottom: 40px;
}

.item-breadcrumb{
    cursor: pointer;
}

.documents {
    width: 360px;
    padding-left: 24px;
}

@media only screen and (max-width: 470px) {
    .title-view{
        font-weight: 800;
        font-size: 1.5rem;
        margin-top: 0;
    }

    .title-view img{
        width: 16px;
    }
    .image-home-hand img {
        max-width: 220px;
        width: 100%;
        margin-top: 22px;
    }

    .box-title-breadcrumbs {
        width: 100%;
        height: 114px;
        background: #F2F5F5;
    }
    .documents {
        width: 273px;
        padding-left: 24px;
        font-size: 12px;
    }
    .title-modal {
        padding-bottom: 10px;
    }
    
}