.container-home{
    text-align: center;
    padding-top: 50px;
    padding-bottom: 10rem;
}

.card-home{
    width: 338px;
    height: 93px;

    /* justo blanco */
    background: #FFFFFF;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.15);
    border-radius: 10px;
    margin-bottom: 35px;
    cursor: pointer;
}

.card-home-disable{
    opacity: 0.5;
}

.title-card{
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 24px;

    /* identical to box height */

    /* justo verde */
    color: #2D4838;


    /* Inside Auto Layout */
    flex: none;
    order: 0;
    flex-grow: 0;
    margin: 10px 0px;
    float: left;
}

.button-card{
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;

    /* justo negro */
    color: #333333;

    opacity: 0.5;

    /* Inside Auto Layout */
    flex: none;
    order: 1;
    flex-grow: 0;
    margin: 10px 0px;
    float: left;
}

.button-card-success{
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;

    color: #31CC2E;

    /* Inside Auto Layout */
    flex: none;
    order: 1;
    flex-grow: 0;
    margin: 10px 0px;
    float: left;
}

.button-card-success .img{
    float: right;
    width: 25px;
    padding-top: 1px;
    padding-left: 5px;
}

.card-home img{
    float: right;
    width: 18px;
    padding-top: 10px;
}

.button-finish{
    width: 338px;
    height: 60px;

    /* justo verde */
    background: #2D4838;
    opacity: 0.2;
    border-radius: 5px;
}

.button-finish-active{
    opacity: 1 !important;
}

.button-finish .text{
    font-family: Lato;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;

    /* identical to box height */
    text-align: center;

    /* justo blanco */
    color: #FFFFFF;
}

.center-card{
    text-align: -webkit-center;
    text-align: -moz-center;
}

@media only screen and (max-width: 470px) {
    .card-home{
        width: 100%;
    }
    .button-finish{
        width: 100%;
    }
    .card-home {
        margin-bottom: 30px;   
    }
    .container-home{
        text-align: center;
        padding-top: 30px;
        padding-bottom: 3rem;
    }
}

