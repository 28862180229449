.title-table-orders{
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 12px;

    /* identical to box height */
    text-transform: uppercase;

    /* justo verde */
    color: #2D4838;
}

.icon-export-provider{
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    text-align: center;

    /* justo negro */
    color: #333333;
    float: left;
    padding-right: 5px;
    padding-left: 5px;
    margin-top: -8px;
}

.icon-export-provider a{
    color: #333333;
    font-size: 12px;
    line-height: 14px;
}

.icon-export-provider a:hover{
    text-decoration: none;
}


.icon-export-provider.empty{
    background: #e0e4e2;
}

.empty img{
    padding-top: 5px;
    padding-bottom: 3px;
}

.icon-action-provider{
    padding-right: 10px;
    padding-left: 10px;
    float: left;
}

.body-provider{
    margin-left: 80px;
    padding-bottom: 50px;
}

.status-orders{
    width: 123px !important;
    height: 29px !important;
}

.btnAddProduct{
    margin-top: 29px;
    width: 165px;
    height: 50px;

    border: 1px solid rgba(45, 72, 56, 0.5);
    box-sizing: border-box;
    border-radius: 5px;

    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 22px;
    color: #333333;
    background: white;
}

.total-orders{
    padding: 10px;
    width: 100%;
    text-align: right;
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: 10px;
    line-height: 12px;

    /* identical to box height */
    text-transform: uppercase;

    /* justo verde */
    color: #2D4838;
}

.total-orders span{
    padding-left: 30px;
    padding-right: 10px;
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 22px;

    /* identical to box height */

    /* justo negro */
    color: #333333;
}

.created_at{
    padding-left: 15px;
}

.icon-remove{
    margin-top: -1px;
}

.item-actions{
    float: right;
}

.text-center{
    text-align: center;
}

.container-select-day {
    margin: 0 auto;
    width: 98%;
}

.item-privider {
    font-family: Lato;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    color: #333333;
    padding-bottom: 30px;
}

.label-order {
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;
    color: #333333;
    padding-bottom: 10px;

}

.container-modal-btn-schedule {
    width: 100%;
    margin-top: 40px;
}