.container-finish{
    background: #F2F5F5;
    padding-bottom: 50px;
}

.image{
    height: 150px;
    padding-top: 40px;
    width: 100%;
}

.image .one{
    float: right;
    padding-right: 61px;
    display: flex;
}

.image .two{
    float: left;
    padding-left: 50px;
    display: flex;
}

.card{
    width: 500px;
    height: 480px;

    /* justo blanco */
    background: #FFFFFF;
    border: 0.802065px solid rgba(45, 72, 56, 0.2);
    box-sizing: border-box;
    border-radius: 8.02065px;
    text-align: center;
}

.card .text{
    font-family: Lato;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 24px;
    text-align: center;

    /* justo negro */
    color: #333333;

    opacity: 0.7;
}

.button-finish-finish{
    width: 338px;
    height: 60px;

    /* justo verde */
    background: #2D4838;
    border-radius: 5px;
}

.button-finish-finish .text{
    font-family: Lato;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;

    /* identical to box height */
    text-align: center;

    /* justo blanco */
    color: #FFFFFF;
    opacity: 1 !important;
}

.center-card{
    text-align: -webkit-center;
    text-align: -moz-center;
}

@media only screen and (max-width: 470px) {
    .card{
        width: 100%;
    }
    .button-finish-finish{
        width: 100%;
    }
    .image{
        height: 0;
        width: 0;
    }
    .image .one{
        display: none;
    }

    .image .two {
        display: none;
    }


}