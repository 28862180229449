

.container {
    display: flex;
    width: 574px;
    height: 580px;
    left: 141px;
    top: 231px;
    
    background: #F2F5F5;
      
    }
    .div {
        display: flex;
        flex-direction: column;
        align-items: center
    }
    .div h3 {
        width: 364px;
        height: 19px;
        left: 246px;
        top: 464px;
        
        font-family: Lato;
        font-style: normal;
        font-weight: bold;
        font-size: 16px;
        line-height: 19px;
        text-align: center;
        
        /* justo negro */
        
        color: #333333;
    }
    .avatar {
      margin: 1px;
      background-color: #2D4838;
    }
    .button {
        width: 360px;
        height: 60px;        
        /* justo verde */
        
        border: 1px solid #2D4838;
        box-sizing: border-box;
        border-radius: 5px;
    }
    
    .buttonLogin {
        width: 100%;
        height: 60px;
        margin-top: 20px;
        background: #F2F5F5;
        border-radius: 5px;
        border: none;
        font-size: 18px;
    }

    .buttonLogin-active, .buttonRegister {
      text-transform: capitalize;
      font-family: Lato;
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      margin-top: 20px;
      box-Sizing: border-box;
      border-radius: 5px;
      background-color: #2D4838;
      color: #fff;
      border: none;
      width: 100%;
      height: 60px;
    }
    /* .buttonLogin:hover {
      background-color: #2D4838
    } */
    
    .buttonRegister {
      border: 1px solid #2D4838;
      line-height: 22px;
      color: #2D4838;
      background-color: #F2F5F5;
      width: 360px;
    }
    
    /* .img {
      position: 'relative',
      display: 'flex',
      top: '-31px',
      left: '151px'
    },
    .imgbttn {
      position: 'relative',
      display: 'flex',
      botton: '0',
      right: '151px',
      width: '103px'
    } */

.error {
  font-size: 14px;
  color: rgb(252, 56, 56);
}

.text-red-700{
  color: red;
}

.text-red-700 .text-xs{
    font-size: 12px;
}


.label-login{
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  /* justo negro */
  color: #333333;
  padding: 10px 0;
  text-transform: none;
}

.label-login p{
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 17px;
  color: #333333;
  opacity: 0.5;
}

.label-title-register {
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  text-transform: uppercase;

  /* justo negro */

  color: #333333;
}

.img-register, .img2 {
  display: flex;
  position: relative;
}

.img-register {
  top: -4rem;
  left: 11rem;
}

.img2 {
  left: -11rem;
  bottom: -4rem;
}

.span {
  cursor: pointer;
  text-decoration: underline;
  text-transform: none;
}

.TextField {
  height: 60px;
  box-sizing: border-box;
  border-radius: 5px;
  margin: 0,
}



@media only screen and (max-width: 470px) {
  
  .label-title-register {
    font-size: 14px;
    line-height: 17px;
  }
}

@media only screen and (max-width: 786px) {
  .button {
    width: 100%
  }

  .img, .img2 {
    display: none;
  }
}

@media only screen and (max-width: 1024px) {
  .img {
    top: -4rem;
    left: 8rem;
  }
  
  .img2 {
    left: -6rem;
    bottom: -4rem;
  }

  .buttonRegister {
    width: 100%;
  }
}


