.section-register {
  padding-top: 30px;
  padding-bottom: 50px;
  border-bottom: 0.75px solid #2d483824;
}

.title-section {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  justify-content: space-between;
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  text-transform: uppercase;
  color: #333333;
  opacity: 0.7;
  margin: 40px 0;
}

.label-register {
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;

  /* justo negro */
  color: #333333;
  padding-bottom: 10px;
  height: 40px;
}

.label-register-password {
  height: 40px;
  line-height: 16px;
}

.label-register p {
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 17px;

  /* identical to box height */

  /* justo negro */
  color: #333333;

  opacity: 0.5;
}

.input-register {
  width: 100%;
  height: 60px;

  /* justo blanco */
  background: #ffffff;
  border: 1px solid rgba(45, 72, 56, 0.2);
  box-sizing: border-box;
  border-radius: 5px;
  padding-left: 10px;
}
.input-oc {
  width: 100%;
  height: 60px;

  /* justo blanco */
  background: #ffffff;
  border: 1px solid rgba(45, 72, 56, 0.2);
  box-sizing: border-box;
  border-radius: 5px;
  padding-left: 10px;
}

.button {
  width: 460px;
  height: 60px;

  /* justo blanco */
  background: #ffffff;

  /* justo verde */
  border: 1px solid #2d4838;
  box-sizing: border-box;
  border-radius: 5px;
  margin-bottom: 50px;
}

.button .img-button {
  text-align: center;
  padding-top: 17px;
}

.button .text-button {
  padding-top: 15px;
  font-family: Lato;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;

  /* identical to box height */
  text-align: center;

  color: #2d4838;
}

.button-load-register {
  width: 100%;
  height: 60px;

  background: rgba(2, 70, 56, 0.05);
  border: 1px solid rgba(45, 72, 56, 0.2);
  box-sizing: border-box;
  border-radius: 5px;
}

.button-load-register .img-button-not-loaded {
  text-align: center;
  padding-top: 5px;
}

.button-load-register .text-button-not-loaded {
  padding-top: 5px !important;
}

.button-load-register .img-button {
  text-align: center;
  /* padding-top: 17px; */
  background: transparent;
}

.img-button img {
  padding-top: 17px;
}

.button-load-register .text-button {
  padding-top: 17px;
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 13px !important;
  line-height: 17px;

  /* identical to box height */

  /* justo negro */
  color: #333333;

  opacity: 0.7;
  text-align: left;
}

.button-load-register .text-load {
  padding-top: 15px;
  padding-left: 10px;
  font-family: Lato;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;

  /* identical to box height */

  /* justo negro */
  color: #333333;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.text-underline {
  border-bottom: 2px solid #333333;
}

.text-underline-load {
  border-bottom: 1px solid #333333;
}

.button-save {
  width: 338px;
  height: 60px;

  /* justo verde */
  background: #2d4838;
  opacity: 0.2;
  border-radius: 5px;
}

.button-save-active {
  width: 338px;
  height: 60px;

  /* justo verde */
  background: #2d4838;
  opacity: 1;
  border-radius: 5px;
}

.button-save .text,
.button-save-active .text {
  font-family: Lato;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;

  /* identical to box height */
  text-align: center;

  /* justo blanco */
  color: #ffffff;
}

#selectFileBankFace,
#selectFileOpinionFulfillmentSat,
#selectFileProofAddress,
#selectFileProofTaxSituation,
#selectFileConstitutiveAct,
#selectFileOpinionFulfillmentTaxObligations,
#selectFilePowerAttorney,
#selectFileIdentificationLegalRepresentativeFront,
#selectFileIdentificationLegalRepresentativeReverse {
  width: auto !important;
  display: none;
}

.MuiGrid-spacing-xs-10 > .MuiGrid-item {
  padding-bottom: 0px !important;
  padding-top: 20px !important;
}

.button-register {
  width: 100%;
  padding-bottom: 60px;
  text-align: center;
}

.MuiNativeSelect-select:focus {
  background-color: transparent !important;
}

.linear-progress {
  padding: 10px;
}

.borF {
  border-bottom: none;
}

@media only screen and (max-width: 470px) {
  .label-register {
    font-size: 16px;
  }

  .label-register-password {
    height: 60px;
  }
  .button-save {
    width: 100%;
  }

  .button-register {
    width: 100%;
    margin-top: 0;
    padding-bottom: 30px;
    text-align: center;
  }
  .title-section {
    margin: 20px 0;
  }
}
