.icon-user {
    width: 26px;
    height: 26px;
    left: 421px;
    top: 336px;
    object-fit: none;
    /* justo blanco */

    background: #FFFFFF;
    border: 1px solid rgba(51, 51, 51, 0.1);
    border-radius: 50%;
    margin: 10px 10px 0 0;
}

.icon-user img {
    left: 8.6%;
    right: 8.48%;
    top: 8.6%;
    bottom: 8.31%;
}

.content-user {
    font-family: Lato;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    display: flex;
    align-items: center;
    color: #333333;
}

.date-send {
    width: 176px;
    width: 176px;
    opacity: 1;
    font-family: Lato;
    font-style: italic;
    font-weight: normal;
    font-size: 12px;
    padding-top: 10px;
    color: #929494;
}

.container-message {
    border-top: 1px solid rgba(0, 0, 0, .1);
    padding: 5px;
    margin-top: 2px;
}
.text-details-incidence {
    font-family: Lato;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: left;
    padding: 0 0 0 37px;
    color: #333333;
}

.link-file {
    color: rgba(0,0,0,250,.5);
    font-weight:'500';

}

.text-details-incidence img {
    padding: 32px 0;
}

.content-bg, .content {
    width: 100%;
    height: 167px;
    left: 398px;
    top: 657px;
    padding: 16px 0 0 16px;
    background: #F2F5F5;
    border-top: 1px solid rgb(51 51 51 / 16%);
    border-bottom: 1px solid rgb(51 51 51 / 16%);

}

.content {
    background: #FFFFFF;
    border-top: none;
    border-bottom: none;
    height: 100%;
    min-height: 167px;
}

.container-btn-response {
    padding: 30px 0;
}